export const SELECT_MEMBERSHIPS = 1
export const USER_DETAILS = 2
export const PAYMENT = 3
export const SUCCESS = 4
export const NOT_FOUND = 5 
export const LOADING = 6
export const UPLOAD_DOCUMENT = 7
export const VERIFY_FAIL = 8
export const DATE_SELECT = 9
export const CONFIRM_DUPLICATE_MEMBERSHIP = 10
export const EVENTS = 11
export const TICKET_COUNT_SELECT = 12
export const VERIFY = 13
export const SELECT_MEMBERSHIP_PAGE = 14
export const FORM_PAGE = 15
export const INTEREST_FORM_PAGE = 16
export const REGISTER_INTEREST_CONFIRM = 17
export const INTEREST_INFO_PAGE = 18
export const VERIFY_INTEREST = 19
export const ALL_INTERESTS_PAGE = 20
