import React from 'react'
import Header from './shared/Header';
import { USER_DETAILS } from './shared/Steps';
import InterestCard from './InterestCard';
import { getLanguage } from './shared/Token';

const InterestInfo = ({ prevStep, nextStep, handleChange, values }) => {

  var { interest, first_name, organization_details } = values
    const language = getLanguage()

  const Continue = e => {
    e.preventDefault();

    const meta = { interests: [{ id: interest?.id }] }
    handleChange('meta', meta)

    nextStep(USER_DETAILS)
  }

  return (
    <>
      <Header title="Select Membership" first_name={first_name} />

        {!interest ?
            <div className="container">
            <div className="row">
                <div className="col-12 mx-auto my-4 apply-btn mw-container">
                <h6>We're sorry, an error occurred.</h6>
                </div>
            </div>
            </div>
            : <InterestCard interest={interest} organization_details={organization_details} language={language} />
        }

        <div className="container">
            <div className="form-row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
                <button type="button" className="btn btn-primary btn-lg w-100" onClick={Continue}>Next</button>
            </div>
            </div>
        </div>

    </>
  )

}

export default InterestInfo
