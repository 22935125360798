export const getInterestName = (interest, language) => {
    const defaultName = interest?.name ?? ""
    if (language === 'en' && interest?.name_en) {
        return interest?.name_en
    }
    return defaultName
  }

export const getInterestDescription = (interest, language) => {
    const defaultDescription = interest?.description ?? ""
    if (language === 'en' && interest?.description_en) {
        return interest?.description_en
    }
    return defaultDescription
}

export const getInterestShortDescription= (interest, language) => {
    const defaultShortDescription = interest?.short_description ?? ""
    if (language === 'en' && interest?.short_description_en) {
        return interest?.short_description_en
    }
    return defaultShortDescription
}