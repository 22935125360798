export const TRIA_API = process.env.REACT_APP_TRIA_API || 'http://127.0.0.1:3005'

export async function getPackages(organizationId, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/packages?organization_id=' + organizationId
    get(url, null, onSuccess, onError)
}

export async function getOrganizationEvents(organizationId, onSuccess, onError) {
    let url = TRIA_API + '/organization/' + organizationId + '/organization-events'
    get(url, null, onSuccess, onError)
}

export async function getInterestById(interestId, onSuccess, onError) {
    const url = TRIA_API + '/onboarding/interest/' + interestId
    get(url, null, onSuccess, onError)
}

export async function getInterestsByOrganizationId(organizationId, onSuccess, onError) {
    const url = TRIA_API + '/onboarding/interests?organization_id=' + organizationId
    get(url, null, onSuccess, onError)
}

export async function login(username, password, onSuccess, onError) {
    let url = TRIA_API + '/login/username'
    let data = {
        username,
        password
    } 
    post(url, data, null, onSuccess, onError)
}

export async function canCompletePackage(packageId, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/package/' + packageId + '/verify'
    get(url, accessToken, onSuccess, onError)
}

export async function verifyTicket(ticket, meta, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/ticket/verify'
    let data = {
        ticket: ticket,
        meta: meta
    }
    post(url, data, accessToken, onSuccess, onError)
}

export async function verifyInterest(interestId, accessToken, onSuccess, onError) {
    const url = TRIA_API + '/onboarding/interest/' + interestId + '/verify'
    get(url, accessToken, onSuccess, onError)
}

export async function getTicketWithForm(ticketId, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/ticket/' + ticketId + '/form'
    get(url, accessToken, onSuccess, onError)
}

export async function getInterestWithForm(interestId, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/interest/' + interestId + '/form'
    get(url, accessToken, onSuccess, onError)
}

export async function getOrganizationById(organizationId, onSuccess, onError) {
    let url = TRIA_API + '/organization/' + organizationId
    get(url, null, onSuccess, onError)
}

export async function getOrganizationDetails(organizationId, onSuccess, onError) {
    let url = TRIA_API + '/organization/' + organizationId + '/organization-details'
    get(url, null, onSuccess, onError)
}

export async function getFreeTransactionBySecureId(id, onSuccess, onError) {
    var url = TRIA_API + '/onboarding/transaction/secure/'+ id
    get(url, '', (result) => {
        if (result) {
            onSuccess(result)
        } else {
            onError("No transaction data available")
        }
    }, onError)
}

export async function getMemberInterestBySecureId(id, onSuccess, onError) {
    var url = TRIA_API + '/onboarding/member-interests/secure/'+ id
    get(url, '', (result) => {
        if (result) {
            onSuccess(result)
        } else {
            onError("No data available")
        }
    }, onError)
}

export async function getTransactionById(id, onSuccess, onError) {
    var url = TRIA_API + '/onboarding/transaction/'+ id
    get(url, '', (result) => {
        if (result) {
            onSuccess(result)
        } else {
            onError("No transaction data available")
        }
    }, onError)
}

export async function verifyPayment(transactionId, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/verify-payment'
    let data = {
        transaction_id: transactionId
    }
    post(url, data, null, onSuccess, onError)
}

export async function createPendingMember(firstName, lastName, personalIdentificationNumber, email, mobileNumber, password, repeatPassword, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/member'
    let data = {
        first_name: firstName,
        last_name: lastName,
        personal_identification_number: personalIdentificationNumber,
        email: email,
        mobile_number: mobileNumber,
        password: password,
        repeat_password: repeatPassword
    }
    post(url, data, null, onSuccess, onError)
}

export async function getMember(accessToken, onSuccess, onError) {
    var url = TRIA_API + '/member/profile'
    get(url, accessToken, (result) => {
        if (result.member) {
            onSuccess(result.member)
        } else {
            onError("No member data available")
        }
    }, onError)
}

export async function createPaymentIntent(_package, ticket, email, meta, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/stripe/create-payment-intent'
    let data = {
        package: _package,
        ticket: ticket,
        email: email
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function changeTransactionEmail(transactionId, email, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/payment/change-email'
    const data = {
        transaction_id: transactionId,
        email: email
    }
    post(url, data, accessToken, onSuccess, onError)
}

export async function createNetsPayment(_package, ticket, email, meta, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/nets/create-payment'
    let data = {
        package: _package,
        ticket: ticket,
        email: email
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function updateNetsPayment(_package, ticket, email, meta, accessToken, discount_code, payment_id, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/nets/update-payment'
    let data = {
        package: _package,
        ticket: ticket,
        email: email,
        discount_code: discount_code,
        payment_id: payment_id
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function registerMemberInterest(interestId, _package, occurrence, meta, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/register-member-interest'
    let data = {
        interest_id: interestId,
        package: _package,
        occurrence: occurrence
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function createTransaction(_package, ticket, email, meta, accessToken, discount_code, payment_id, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/create-transaction'
    let data = {
        package: _package,
        ticket: ticket,
        email: email,
        discount_code: discount_code,
        payment_id: payment_id
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function createSwishPaymentRequest(_package, ticket, email, meta, mobileNumber, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/swish/create-payment-request'
    let data = {
        package: _package,
        ticket: ticket,
        email: email,
        mobile_number: mobileNumber,
    }

    if (meta) {
        data.meta = meta
    }

    post(url, data, accessToken, onSuccess, onError)
}

export async function createMemberVerification(accessToken, formData, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/member-verification'
    postFile(url, formData, accessToken, onSuccess, onError)
}

function getHeaders(accessToken) {
    var headers = {}
    if (accessToken) {
        headers = {
            'Authorization': 'Bearer ' + accessToken
        }
    }
    return headers
}

async function get(url, accessToken, onSuccess, onError) {
    
    fetch(url, {
        headers: getHeaders(accessToken)
    })
    .then((response) => {
        return response.json()
    })
    .then((result) => {
        if (result == null) {
            onError("No result")
        } else {
            onSuccess(result)
        }
    })
    .catch(err => {
        onError(err)
    })
}

async function postFile(url, data, accessToken, onSuccess, onError) {
    var headers = getHeaders(accessToken)
    fetch(url, {
        method: 'POST',
        headers: headers,
        body: data
    }).then((response) => {
        return response.json()
    })
    .then((result) => {
        if (!result.error) {
            onSuccess(result)
        } else {
            onError(result)
        }
    })
    .catch(err => {
        onError(err)
    })
}

async function post(url, data, accessToken, onSuccess, onError) {
    var headers = getHeaders(accessToken)
    headers['Content-Type'] = 'application/json; charset=UTF-8'
    fetch(url, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(data)
    }).then((response) => {
        return response.json()
    })
    .then((result) => {
        if (!result.error) {
            onSuccess(result)
        } else {
            onError(result)
        }
    })
    .catch(err => {
        onError(err)
    })
}

export async function swishMonitor(uuid, accessToken, onSuccess, onError) {
    let url = TRIA_API + '/onboarding/swish/monitor'
    let data = {
        id: uuid,
    }

    post(url, data, accessToken, onSuccess, onError)
}