import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-light py-4">
      <hr />
      <div className="container membership">
        <div className="row">
          <div className="col-12 mx-auto text-center">
            <div className="card mw-container bg-light">
              <div className="container">
                <div className="row">
                  <div className="col-12 col-md-6">
                    <p>&copy; 2025 <strong>Addreax Nordic AB</strong><br />Org.nr: 556845-1842</p>
                    <a href="/terms">Terms and Conditions</a>
                  </div>
                  <div className="col-12 col-md-6">
                    <p>Drottninggatan 32<br />111 51 Stockholm</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
