import React, { useState } from "react";
import MembershipCard from './MembershipCard';
import Header from './shared/Header'
import { DATE_SELECT } from "./shared/Steps";
import DateSelect from './DateSelect'

const Membership = ({ nextStep, prevStep, handleChange, packageId, values }) => {

  var { first_name, packages, selected_package, meta, organization_details } = values


  // for continue event listener
  const Continue = (e, pack) => {
    e.preventDefault();
    var pendingMembershipSubscriptions = []

    for (var _package of packages) {
      if (_package.id === pack) {
        for (var membershipSubscription of _package.membershipSubscriptions) {
          if (membershipSubscription.duration_type === 3) {
            pendingMembershipSubscriptions.push(membershipSubscription)
          }
        }

      }
    }

    if (pendingMembershipSubscriptions.length > 0) {
      nextStep(DATE_SELECT)
    } else {
      nextStep();
    }

  }


  var pendingMembershipSubscriptions = []
  // filter packageId
  if (packageId) {
    packageId = parseInt(packageId)
    if (selected_package !== packageId) {
      values.selected_package = packageId
      handleChange('selected_package', packageId)
    }

    if (packages && packages.length > 0) {
      packages = packages.filter((_package) => _package.id === packageId)
    }

    for (var _package of packages) {
      if (_package.id === packageId) {
        for (var membershipSubscription of _package.membershipSubscriptions) {
          if (membershipSubscription.duration_type === 3) {
            pendingMembershipSubscriptions.push(membershipSubscription)
          }
        }
      }
    }

  }


  return (
    <>
      <Header title="Select Membership" first_name={first_name} />

      {organization_details &&
        <div className="container membership">
          <div className="row overflow-hidden"><div className="col-12 mx-auto">
            <div className="card mw-container">
              {organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
              <div className="card-body bg-white d-flex justify-content-between align-items-center topPackages">
                <div>
                  {organization_details.name && <h5 className="card-title">{organization_details.name}</h5>}
                </div>
                {organization_details.logo_image_url && <img src={organization_details.logo_image_url} height="65" alt="logo" className="unionLogo" />}
              </div>
            </div>
          </div>
          </div>
        </div>
      }

      {packages.length < 1 ?
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
              <h6>We're sorry, but at this time there are no membership options available. Please check back later as new options may become available.</h6>
            </div>
          </div>
        </div>
        : packages.map(function (pack, i) {
          return <MembershipCard pack={pack} key={i} handleChange={handleChange} values={values} checkbox={true} Continue={Continue} organization_details={organization_details} />
        })
      }


      {pendingMembershipSubscriptions.length > 0 &&
        <DateSelect
          prevStep={prevStep}
          nextStep={nextStep}
          handleChange={handleChange}
          values={values}
        />
      }



      {/* {packages  && packages.length > 0 && 
          <div className="container">
            <div className="form-row">
              <div className="col-12 mx-auto my-4 apply-btn mw-container">
                <button type="button" className="btn btn-primary btn-lg w-100" onClick={Continue} disabled={selected_package === null} >Next</button>
              </div>
            </div>
          </div>
        } */}

    </>
  )

}

export default Membership