import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { getAccessToken, saveToken, removeTokens } from './shared/Token';
import { login, getMember, createPendingMember, canCompletePackage, createMemberVerification } from '../api/apiHelper'
import { USER_DETAILS } from './shared/Steps';

const DateSelect = ({ prevStep, nextStep, handleChange, values }) => {

  var { scope, first_name, verificationError, organization, packages, selected_package, org_id, meta } = values

  const [pendingMembershipSubscriptions, setPendingMembershipSubscription] = useState([])
  const [selectedPackage, setSelectedPackage] = useState([])
  const [dateValidation, setDateValidation] = useState([]);

  const Continue = e => {
    e.preventDefault();
    handleChange('selected_package', selected_package)
    nextStep(USER_DETAILS)
  }

  const Previous = e => {
    e.preventDefault();
    // Reset meta on back!
    handleChange('meta', null)
    if (scope === 'package') {
      nextStep(1)
    } else {
      prevStep();
    }
  }

  useEffect(() => {
    var pendingMembershipSubscriptions = []
    for (var _package of packages) {
      if (_package.id === selected_package) {

        for (var membershipSubscription of _package.membershipSubscriptions) {
          if (membershipSubscription.duration_type === 3) {
            pendingMembershipSubscriptions.push(membershipSubscription)
          }
        }
        setSelectedPackage(_package)
      }
    }
    
    // setDateValidation(Array(pendingMembershipSubscriptions.length).fill("")); 

    if (pendingMembershipSubscriptions.length > 0 && !allDatesValid(pendingMembershipSubscriptions)) {
      setPendingMembershipSubscription(pendingMembershipSubscriptions)
    }

  }, []);

  // can only select dates between today and 90 days in the future
  const getDatePeriod = () => {
    let dateMin = new Date().toISOString().split("T")[0];

    var today = new Date();
    var dateMax = new Date();
    dateMax.setDate(today.getDate() + 90);
    dateMax = dateMax.toISOString().split("T")[0];

    if (pendingMembershipSubscriptions.length > 0) {
      dateMin = pendingMembershipSubscriptions[0].purchase_availability_start_date
      dateMax = pendingMembershipSubscriptions[0].purchase_availability_end_date
    }

    return { dateMin, dateMax }
  }

  const allDatesValid = (membershipSubscriptions) => {
    for (var membershipSubscription of membershipSubscriptions) {
      if (!membershipSubscriptionMetaValid(membershipSubscription)) {
        return false
      }
    }
    return true
  }

  // returns true if the id of membershipSubscription exists in the meta object, and the membership_start_date is not empty
  const membershipSubscriptionMetaValid = (membershipSubscription) => {
    if (meta && meta.membership_subscriptions && meta.membership_subscriptions.length > 0) {
      for (var membershipSubscriptionMeta of meta.membership_subscriptions) {
        if (membershipSubscriptionMeta.id === membershipSubscription.id && membershipSubscriptionMeta.membership_start_date) {
          return true
        }
      }
    }
    return false
  }

  const onChangePackageTransactionMeta = (membershipSubscription, value, index) => {

    let membershipSubscriptionId = membershipSubscription.id
    const dateValue = new Date(value).toISOString().split('T')[0];

    var { dateMin, dateMax } = getDatePeriod()

    if (membershipSubscription.purchase_availability_start_date) {
      dateMin = new Date(membershipSubscription.purchase_availability_start_date).toISOString().split("T")[0]
    }
    if (membershipSubscription.purchase_availability_end_date) {
      dateMax = new Date(membershipSubscription.purchase_availability_end_date).toISOString().split("T")[0]
    }


    if (dateValue < dateMin || dateValue > dateMax) {
      //setDateValidation("The date must be between " + dateMin + " and " + dateMax);
      setDateValidation((prevState) => {
        const newState = [...prevState];
        newState[index] = dateValue < dateMin || dateValue > dateMax ? "The date must be between " + dateMin + " and " + dateMax : "";
        return newState;
      });
    } else {
      setDateValidation((prevState) => {
        const newState = [...prevState];
        newState[index] = "";
        return newState;
      });
    }

    var newMeta
    if (!meta) {
      newMeta = { membership_subscriptions: [{ id: membershipSubscriptionId, membership_start_date: value }] }
    } else {
      newMeta = meta

      let newMembershipSubscriptions = meta.membership_subscriptions

      let currentMeta = getMembershipSubscriptionMeta(membershipSubscriptionId)
      if (currentMeta) {
        for (var newMembershipSubscription of newMembershipSubscriptions) {
          if (newMembershipSubscription.id === membershipSubscriptionId) {
            newMembershipSubscription.membership_start_date = value
          }
        }
      } else {
        newMembershipSubscriptions.push({ id: membershipSubscriptionId, membership_start_date: value })
      }

      newMeta.membership_subscriptions = newMembershipSubscriptions
    }

    handleChange('meta', newMeta)
  }

  const getMembershipSubscriptionMeta = (membershipSubscriptionId) => {
    if (meta && meta.membership_subscriptions && meta.membership_subscriptions.length > 0) {
      for (var membershipSubscriptionMeta of meta.membership_subscriptions) {
        if (membershipSubscriptionMeta.id === membershipSubscriptionId) {
          return membershipSubscriptionMeta
        }
      }
    }
    return null
  }

  const isAllEmpty = (currentValue) => currentValue === "";

  const getConfirmText = (pendingMembershipSubscription, index) => {
    let meta = getMembershipSubscriptionMeta(pendingMembershipSubscription.id)
    if (meta) {

      // print validTo string?
      var validTo = new Date(meta.membership_start_date)
      validTo.setDate(validTo.getDate() + pendingMembershipSubscription.duration_days)

      return <div className="alert alert-info mt-2">
        Membership will be active starting from {meta.membership_start_date} and be valid for {pendingMembershipSubscription.duration_days} days
      </div>
    }
    return null
  }

  var { dateMin, dateMax } = getDatePeriod()


  return (
    <>
      {/* <Header title="Select start date" first_name={first_name} /> */}
      <div className="container mx-auto membership selectDate">
        <div className="row">


          <div className="col-12 mx-auto overflow-hidden">

            {pendingMembershipSubscriptions.map((pendingMembershipSubscription, index) => {

              if (pendingMembershipSubscription.purchase_availability_start_date) {
                dateMin = new Date(pendingMembershipSubscription.purchase_availability_start_date).toISOString().split("T")[0]
              }
              if (pendingMembershipSubscription.purchase_availability_end_date) {
                dateMax = new Date(pendingMembershipSubscription.purchase_availability_end_date).toISOString().split("T")[0]
              }

              return <div className="card mw-container selectDate-item" key={pendingMembershipSubscription.id}>

                <div className="card-footer">
                  <div>
                    {/* {pendingMembershipSubscription.membership.name} | Duration: {pendingMembershipSubscription.duration_days} days */}
                    <p className="selectHeader mt-3">Please select starting date for membership <strong>{pendingMembershipSubscription.membership.name}</strong> below:</p>

                  </div>

                  <div className="my-2">
                    <span className="startDate">Start Date</span>
                    <div className="input-group mb-3">
                      <input className="form-control" type="date" min={dateMin}
                        max={dateMax} onChange={(e) => onChangePackageTransactionMeta(pendingMembershipSubscription, e.target.value, index)}></input>
                    </div>
                  </div>
                  {
                    dateValidation[index] ?
                      <div className="alert alert-danger text-dark mt-2">{dateValidation[index]}</div>
                      :
                      getConfirmText(pendingMembershipSubscription, index)
                  }
                </div>
              </div>
            })}
          </div>
        </div>


        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5 pb-5">
              <div className="d-flex justify-content-between mt-4">
                {/* <button type="submit" onClick={Previous} className="btn btn-outline-primary btn-lg btn-block mb-4 px-5">Back</button> */}
                <button type="submit" disabled={!allDatesValid(pendingMembershipSubscriptions) || !dateValidation.every(isAllEmpty) } onClick={Continue} className="btn btn-primary btn-lg btn-block mb-4 px-5 w-100">Next</button>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>

  )
}

export default DateSelect
