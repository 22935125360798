import React, { useState } from 'react';
import { getInterestName, getInterestShortDescription } from './shared/Util';
import ArrowSvg from './shared/arrow.svg';

const InterestListItem = ({ interest, organization_details, language }) => {

  const [activeTab, setActiveTab] = useState('About');

  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
        <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {interest &&
        <div className="container membership packages">
          <div className="row overflow-hidden">

            <div className="col-12 mx-auto" id={'interest-' + interest.id}>

                <div className="row overflow-hidden"><div className="col-12 mx-auto">
                    <div className="card mw-container">

                        <a href={'/' + organization_details.organization_id + '/interests/' + interest.id}>
                        <div className="card-body bg-white d-flex justify-content-between align-items-center ">
                            <div>
                            <h6 className="card-title">{getInterestName(interest, language)}</h6>
                            </div>

                            <div className="form-check">
                            {<img src={ArrowSvg} alt="Arrow" />}
                            </div>
                        </div>
                        </a>

                    </div>
                  </div>
                </div>
               

              <div className="card mw-container packages-item">
                <ul className="nav nav-pills" id="myTab" role="tablist">
                  {interest?.description && <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />}
                </ul>

                {activeTab === 'About' && (
                  <div className="card-footer">
                    <div className="card-text">
                      <div dangerouslySetInnerHTML={{ __html: getInterestShortDescription(interest, language) }} />
                    </div>
                  </div>)
                }
              </div>

            </div>

          </div>
        </div >
      }
    </>
  )

}

export default InterestListItem