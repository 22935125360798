import React, { useState } from "react";
import EventCard from "./EventCard";
import Header from './shared/Header'
import { TICKET_COUNT_SELECT } from "./shared/Steps";
import EventCardList from "./EventCardList";

const Events = ({ nextStep, handleChange, eventId, values, handleEventUpdate }) => {

  var { first_name, events, selected_ticket, meta, organization_details } = values

  // for continue event listener
  const Continue = e => {
    e.preventDefault();
    nextStep(TICKET_COUNT_SELECT)
  }

  // filter eventId
  if (eventId) {
    if (events && events.length > 0) {
      events = events.filter((event) => event.id === parseInt(eventId))
    }
  }


  let eventsOccur = []

  if (events.length > 0) {
    events.map(function (event, i) {
      if (event.organization_event_occurrences) {
        for (let index = 0; index < event.organization_event_occurrences.length; index++) {
          event.organization_event_occurrences[index].image_url = event.image_url
          event.organization_event_occurrences[index].title = event.title
          const element = event.organization_event_occurrences[index];
          eventsOccur.push(element)
        }

      }
    })
  }

  eventsOccur.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
  let eventsAll = {
    organization_event_occurrences: eventsOccur,
    organization: events[0]?.organization
  }

  return (
    <>
      <Header organization_details={organization_details ? organization_details : ''} first_name={first_name} />

      {events.length < 1 ?
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
              <h6>We're sorry, but at this time there are no events available. Please check back later as new events may become available.</h6>
            </div>
          </div>
        </div>
        : !eventId ?
          <EventCardList nextStep={nextStep} eventsAll={eventsAll} handleChange={handleChange} handleEventUpdate={handleEventUpdate} values={values} checkbox={true} count={true} markInactive={true} eventId={eventId} />
          :
          events.map(function (event, i) {
            return <EventCardList nextStep={nextStep} event={event} key={i} handleChange={handleChange} handleEventUpdate={handleEventUpdate} values={values} checkbox={true} count={true} markInactive={true} eventId={eventId} />
          })
      }

      {/* {eventId &&
        <div className="container">
          <div className="form-row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
              <button type="button" className="btn btn-primary btn-lg w-100" onClick={Continue} disabled={selected_ticket === null} >Next</button>
            </div>
          </div>
        </div>
      } */}

    </>
  )

}

export default Events