import Cookies from 'universal-cookie';

const cookies = new Cookies();

export const getAccessToken = () => {
    return cookies.get('accessToken')
}

export const getRefreshToken = () => {
    return cookies.get('refreshToken')
}

export const removeTokens = () => {
   cookies.remove('refreshToken', {path: '/'})
   cookies.remove('accessToken', {path: '/'})
}

export function saveLanguage(language) {
    if (!language) {
        return
    }
    var secure = true
    if (window.location.hostname === 'localhost') {
        secure = false
    }

    const maxAge = 7 * 24 * 60 * 60 // 7 days in seconds
    cookies.set('obLang', language, { path: '/', sameSite: 'Strict', secure: secure, maxAge: maxAge });
}

export function getLanguage() {
    return cookies.get('obLang')
}

export function saveToken(token) {
    var secure = true
    if (window.location.hostname === 'localhost') {
        secure = false
    }
    var maxAge = parseInt(token.expires_in) / 1000

    cookies.set('accessToken', token.access_token, { path: '/', sameSite: 'Strict', secure: secure, maxAge: maxAge });
    if (token.refresh_token) {
        cookies.set('refreshToken', token.refresh_token, { path: '/', sameSite: 'Strict', secure: secure });
    }
}