import React, { useCallback, useEffect, useState, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import fileSvg from './file.svg'
import closeSvg from './close.svg'
//import pdfSvg from './pdf.svg'


// 'Unsupported file format. Only .jpeg, .jpg, .png and .pdf is accepted.
const errorMessages = {
    "too-many-files": 'Too many files! You can upload a maximum of 5 files.',
    "file-invalid-type": 'Unsupported file format. Only .jpeg, .jpg, .png and is accepted.',
    "file-too-large": 'File size is too large! Maximum size is 10 MB.',
    "file-already-uploaded": 'File with the same name has already been uploaded.',
}
var printedErrorTypes = [];
const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '50px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#ced4da',
    borderStyle: 'dashed',
    backgroundColor: '#FFF',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const focusedStyle = {
    borderColor: '#2196f3'
};

const acceptStyle = {
    borderColor: '#00e676'
};

const rejectStyle = {
    borderColor: '#ff1744'
};

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 16,
};

const thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 100,
    // padding: 4,
    boxSizing: 'border-box',
    position: 'relative'
};

const thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden',
    width: '100px',
};

const img = {
    display: 'block',
    width: '100%',
    height: '100%',
    objectFit: 'cover',
};

const FileUpload = ({ onFileUpload, onFileRemove }) => {
    const [files, setFiles] = useState([]);
    const [rejectedFiles, setRejectedFiles] = useState([]);

    function lengthValidator(file) {

        const isFileAlreadyUploaded = files.some((existingFile) => existingFile.name === file.name);
        if (isFileAlreadyUploaded) {
            return {
                code: "file-already-uploaded",
                message: "File with the same name has already been uploaded.",
            };
        }

        if (files.length >= 5) {
            return {
                code: "too-many-files",
                message: `Too many files! You can upload a maximum of 5 files.`
            };
        }

        return null
    }

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
            setRejectedFiles(rejectedFiles);

        } else {
            // Append the new files to the existing files array
            setFiles((prevFiles) => [
                ...prevFiles,
                ...acceptedFiles.map((file) =>
                    Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                ),
            ]);
            printedErrorTypes = [];
            setRejectedFiles(rejectedFiles);
            onFileUpload(acceptedFiles);
        }
    }, [onFileUpload]);

    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject,
    } = useDropzone({
        onDrop,
        maxFiles: 5,
        multiple: true,
        validator: lengthValidator,
        maxSize: 10000000,  // 10 mb
        accept: {
            'image/*': ['.png', '.jpg', '.jpeg']
            //'application/pdf': ['.pdf'],
        }
    });

    const style = useMemo(() => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);

    const generateFileId = (file) => file.name + file.size;

    const removeFileById = (fileIdToRemove) => {
        const updatedFiles = files.filter((file) => generateFileId(file) !== fileIdToRemove);
        updatedFiles.forEach((file) => URL.revokeObjectURL(file.preview));
        setFiles(updatedFiles);
        if (onFileRemove) {
            onFileRemove(fileIdToRemove);
        }
    };


    const thumbs = files.map((file, index) => (
        <div style={thumb} key={generateFileId(file)}>
            <div style={thumbInner}>
                <img
                    className="close"
                    src={closeSvg}
                    width={25}
                    onClick={() => removeFileById(generateFileId(file))} // Attach the click handler
                    alt="" />
                <img
                    className="prevImage"
                    src={file.preview}
                    style={img}
                    onLoad={() => URL.revokeObjectURL(file.preview)}
                    alt="Preview"
                />
            </div>
        </div>
    ));

    useEffect(() => {
        // Revoke data URIs to avoid memory leaks
        return () => files.forEach(file => URL.revokeObjectURL(file.preview));
    }, [files]);

    printedErrorTypes = [];

    return (
        <div>
            <div {...getRootProps({ style })} className="dropzone">
                <input {...getInputProps()} />
                <div className="uploadIcon"><img src={fileSvg} alt="" width={20} /></div>
                <p className="uploadHeader">Upload images/files</p>
                <p className="uploadSubheader">or drag and drop</p>
            </div>

            {rejectedFiles && rejectedFiles.length > 0 && rejectedFiles.map(({ file, errors }) => (
                errors.map((e, index) => {
                    if (!printedErrorTypes.includes(e.code)) {
                        printedErrorTypes.push(e.code);
                        return (
                            <div className="mt-2 mb-1 text-center alert alert-danger text-dark" key={'error-' + index}>
                                {errorMessages[e.code] ?
                                    errorMessages[e.code] :
                                    'Something went wrong, please try again!'}
                            </div>
                        );
                    }
                    return null;
                })
            ))}

            <aside style={thumbsContainer}>{thumbs}</aside>
        </div>
    );
};

export default FileUpload;
