import React from 'react';
import Header from './shared/Header';

const EventTerms = () => {
  return (
    <>
      <Header title="Event Ticket Terms and Conditions" />
      <div className="container mt-4">
        <div className="row">
          <h4>TERMS AND CONDITIONS</h4>

          <div className="col-12 col-md-6">
            <section className="mb-5 mt-5">
              <h4>COMPANY OVERVIEW</h4>
              <p>Addreax Nordic AB. 556845-1842, a company incorporated under the laws of Sweden, with registered address at BOX 7587 , 103 93 Stockholm, Sweden hereinafter referred to as ("Addreax"), provides a service throw both the web an APP hereinafter referred to as (the "service") for purchasing tickets to an event, guestcards and other student related memberships hereinafter referred to as ("event").</p>
            </section>

            <section className="mb-4">
              <h4>GENERAL INFORMATION</h4>
              <p>By using or "service", you are entering into an agreement with the studentorganization hereinafter referred to as ("Union/Nation") that organized and published the "event". "Addreax" handles the processing of "event" sales on behalf of the "Union/Nation responsible for the "event".
                "Addreax" is not responsible for any implementations of the "events"., "Addreax" is not responsible for the quality and content of the "event".
                "Addreax" is not responsible for any financial compensation due to cancellations of the "event".
                All questions or requirements concerning the "event" is handled by the "Union/Nation" which organized and published the "event".
                Specific terms for the "Union/Nation" may apply to an event, and it is your responsibility to review such terms before purchasing an "event"
                By using this "service" you acknowledge the understanding and agreement of these Terms, which also incorporate our Privacy Policy and Cookie Policy when browsing our "service".
                This "service" may only be used for it's intended purpose, all unauthorized use of the "service" will terminate you as a potential user of this "service" and "Addreax" will directly inform the "Union/Nation". Persons under the age of 18 may not use the "service" without parental consent.
              </p>
            </section>

            <section className="mb-5">
              <h4>REFUNDS</h4>
              <p>The usage of this "service" is an agreement between you and the "Union/Nation" that publish/ed and orginaz/ied the "event".
                Information available in the "service" relating to the "event", the ticket price, the fee or the number of available seats and payment methods are information that is provided by the "Union/Nation" to "Addreax" and displayed by "Addreax" on any platform agreed apon between the "Union/Nation" and "Addreax". The payment total price inc VAT, including any additional fees is clearly stated prior to your purchase.
                The payment information provided by this "service" will be transferred by Nets.
                Immediately after your payment you will receive a confirmation e-mail with the "event" to the email address you have provided and as a virtual "event" in your STUK APP. Get in contact with "Addreax" if your purchased "event" dosen't display in the STUK APP or if you don't get a e-mail with the purchase. You are responsible for controlling that your "event" is correct and that the information that you provide in the "service" is correct.
              </p>
            </section>
            <section className="mb-5">
              <h4>TERM AND TERMINATION</h4>
              <p>"Addreax" have the right to suspend any access to the "service" with immediate effect if we have reason to believe that you are violating these Terms. "Addreax" reserve the right to modify, discontinue, temporarily or permanently conclude providing the "service" at any time without prior notice, on our own discretion, or if required by law or by a decision by an authority. You accept that "Addreax" is not liable to you or to any third party for such modification, suspension or discontinuance.</p>
            </section>

          </div>

          <div className="col-12  col-md-6">

            <section className="mb-5 mt-5">
              <h4>DISCLAIMER</h4>
              <p>This "service" is provided 'as is' and "Addreax" do not grant any warranties, express or implied or otherwise, as to the accessibility, quality, fitness for any particular purpose, suitability or accuracy of the "service". You use this "service" is at your own risk. "Addreax" strives to keep this "service" complete and accurate, "Addreax" cannot guarantee the accuracy or completeness of this "service" at any given time. There may be situations when this "service" will not be accessible, including but not limited to necessary maintenance and circumstances outside our control, for which "Addreax" shall never be liable.
              </p>
            </section>

            <section className="mb-5">
              <h4>ACCESS AND AVAILABILITY</h4>
              <p>You are responsible for securing your access to the network necessary to use this "service". Furthermore, you are responsible for obtaining and keeping the necessary hard- or software up to date to access and use our Service.
                This "service" is cloud based and provided via the Internet and actual availability is hence dependent on factors outside of "Addreax"'s control. "Addreax" shall strive to keep this "service" available of no less than 99 %. However, "Addreax" makes no guarantee with regards to such availability and actual availability may be lower.
              </p>
            </section>

            <section className="mb-5">
              <h4>INTELLECTUAL PROPERTY RIGHTS</h4>
              <p>With the exception of third-party products, "Addreax", or its licensor owns, all rights, including intellectual property rights, to this "service" and the software used for providing this "service", including but not limited to patents, copyrights, design rights and trademarks. You may only use the content of the Website/APP for your own use of this "service", and you must not use the content of the Website/APP in breach of applicable law or these user terms. You may use such content only for the purpose of using this "service". We do not grant you any other license to use said intellectual property rights except as expressly set out in these Terms.
                You understand and accept that content posted on this "service" may belong to third parties, and that "Addreax" has no control over such content. You subsequently understand and accept that "Addreax" cannot be held liable for such content supplied by third parties and presented or made available within the scope of this "service". When using this "service", you may also find links to third party websites. Please note that third party websites are beyond our control and that any access by you to any such website is always at your own risk. You acknowledge that we shall not be responsible for the content, functionality, accuracy, legality nor any other aspect of such website. You further acknowledge and agree that we shall not be liable for any damage or loss caused by or in connection with the use of or reliance on any content of such third-party websites.
              </p>
            </section>

            <section className="mb-5">
              <h4>DATA PROTECTION</h4>
              <p>
                When creating your user account, you will be asked to submit certain information about yourself. "Addreax" process personal data received in connection with the use of this "service". The personal data processing is made in accordance with our Privacy Policy.
              </p>
            </section>

            <section className="mb-5">
              <h4>APPLICABLE LAW</h4>
              <p>
                Swedish law shall be applied to these Terms and to any disputes concerning the interpretation or application of the Terms.
                If you are not satisfied with this "service", we recommend that you contact "Addreax" or the "Union/Nation". Otherwise try the dispute by Swedish court, with the Stockholm District Court as the first instance, unless otherwise provided by mandatory law.
              </p>
            </section>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventTerms;
