import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { getAccessToken } from './shared/Token';
import { createMemberVerification } from '../api/apiHelper'
import { PAYMENT } from './shared/Steps';
import FileUpload from './shared/FileUpload';

const Upload = ({ prevStep, nextStep, handleChange, values }) => {

  var { first_name, organization, packages, selected_package, org_id, canCompletePackageResult } = values

  const [fail, setFail] = useState();
  const [verificationText, setVerificationText] = useState();
  const [verificationTypeId, setVerificationTypeId] = useState();

  const [uploadedFiles, setUploadedFiles] = useState([]);

  const getVerificationTypeId = (results) => {
    for (var result of results) {
      if (result.requires_verification) {
        return result.verification_type_id
      }
    }
    return null
  }

  useEffect(() => {
    let verificationTypeId = getVerificationTypeId(canCompletePackageResult)
    if (verificationTypeId) {
      setVerificationTypeId(verificationTypeId)
    } else {
      nextStep(PAYMENT)
    }

  }, [canCompletePackageResult])

  const _package = packages.find(_package => _package.id === selected_package)

  let extraText

  let showTitle = true
  let uploadDocument = true
  let showAboutText = false

  if (verificationTypeId === 1) {
    extraText = 'You have to be active in ' + organization.display_name + ' to continue the purchase of "' + _package.name + '".\nPlease upload a valid study certificate or proof:'
  } else if (verificationTypeId === 2) {
    extraText = 'Member can not be active in ' + organization.display_name
  } else if (verificationTypeId === 3) {
    extraText = 'Member must be a student at university level' + '. Please upload a valid study certificate or proof to continue the purchase of "' + _package.name + '"'
  } else if (verificationTypeId === 5) { 
    showAboutText = true
    showTitle = false
    uploadDocument = false
  }

  const onChangeVerificationText = (text) => {
    setVerificationText(text)
  }


  const handleFileUpload = (acceptedFiles) => {
    // setUploadedFiles(acceptedFiles);
    setUploadedFiles((prevUploadedFiles) => [...prevUploadedFiles, ...acceptedFiles]);

  };

  const handleRemoveFile = (indexToRemove) => {
    const updatedUploadedFiles = [...uploadedFiles];
    updatedUploadedFiles.splice(indexToRemove, 1);
    setUploadedFiles(updatedUploadedFiles);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setFail('')
    // const fileElement = document.getElementById("fileInput");

    let formData = new FormData();
    // for (const file of fileElement.files) {
    //   formData.append('files', file, file.name)
    // }

    // Use acceptedFiles from the FileUpload component
    for (const file of uploadedFiles) {
      formData.append('files', file, file.name);
    }

    if (verificationText) {
      formData.append('text', verificationText)
    }
    formData.append('organization_id', parseInt(org_id))
    formData.append('package_id', parseInt(selected_package))

    let token = getAccessToken()
    createMemberVerification(token, formData, (result) => {
      nextStep(PAYMENT) // TODO DEV-2355 can member purchase right after verifying?
    }, (error) => {
      setFail('Error creating member verification')
    })

  }

  return (
    <>
      <Header title="Verify" first_name={first_name} />
      <div className="container mb-5 mw-container">

        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">

              {showTitle && <h5><strong>We could not verify you!</strong></h5>}
              <p className="extraText">{extraText}</p>

              {showAboutText && <div className="card-footer">
                    <div className="card-text">
                      <div dangerouslySetInnerHTML={{ __html: _package.description }} />
                    </div>
                  </div>}

              <form className="mt-3" onSubmit={handleSubmit}>

                {uploadDocument && <FileUpload onFileUpload={handleFileUpload} onFileRemove={handleRemoveFile} />}
                {/* <div className="input-group mb-3">
                  <input id="fileInput" className="form-control" type="file" multiple={true} accept=".pdf, .jpeg, .jpg, .png" required />
                </div> */}
                {/* <figcaption className="upload-info">Please upload an image file in JPG, PNG, or PDF format.</figcaption> */}

                <div className="mb-3">
                  <label htmlFor="info" className="form-label">Additional Information:</label>
                  <textarea id="info" className="form-control" rows="3" type="text" onChange={(e) => onChangeVerificationText(e.target.value)} />
                </div>

                <button type="submit" className="btn btn-primary btn-lg w-100" disabled={uploadedFiles.length === 0 && uploadDocument}>Next</button>

                {fail && <div className="alert alert-danger text-dark mt-2">{fail}</div>}
              </form>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default Upload
