import React from 'react'
import Header from './shared/Header';

const Loading = () => {
  return (
    <>
      <Header title="Loading..." />
      <div className="d-flex justify-content-center text-primary mt-5">
        <div className="spinner-border"></div>
      </div>
    </>
  )
}

export default Loading
