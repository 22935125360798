import React from 'react';
import DateRangeFormat from './DateRangeFormat';
import { getInterestName } from './shared/Util';
import { getLanguage } from './shared/Token';

const InterestCardSmall = ({ values }) => {
  var { interest, organization_details } = values
  const language = getLanguage()

  const renderEventInterest = (event, occurrence) => {
    return <>
      <div className="row mb-3" key={occurrence.id}>
        <div className="col-12 ticketSelect">

          <dl className="name mb-1">
            <span className="mt-3 d-block">
              <strong>
                {event.title && event.title}
              </strong>
            </span>
          </dl>

          <DateRangeFormat occurrence={occurrence} organization={organization_details} compactStyle={true} />
        </div>
      </div>
    </>
  }

  const renderInterestInfo = (interest) => {
    return <>
      <div className="row mb-3" key={interest.id}>
        <div className="col-12 ticketSelect">

          <dl className="name mb-1">
            <span className="mt-3 d-block">
              <strong>
                {getInterestName(interest, language)}
              </strong>
            </span>
          </dl>

          <div className="wrap-date-location">
            {organization_details && <span className="orgName">{organization_details.display_name ?? organization_details.name}</span>}
         </div>

        </div>
      </div>
    </>
  }

  const renderInterest = (interest) => {
    const event = interest?.organization_event_occurrences?.[0]?.organization_event
    const occurrence = interest?.organization_event_occurrences?.[0]

    if (event) {
        return renderEventInterest(event, occurrence)
    }

    return renderInterestInfo(interest)
  }

  return (
    <>
        <div className="container membership">
          <div className="row">
            <div className="col-12 mx-auto mt-3" id={'interest-' + interest.id}>
              <div className="mw-container mx-auto" htmlFor={interest.id}>
                <h6 className="mb-1"><strong>Registration of interest</strong></h6>
                <div className="card">
                  <div className="card-footer">
                    {renderInterest(interest)}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </>
  )

}

export default InterestCardSmall