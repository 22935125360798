import ArrowSvg from './shared/arrow.svg';
import refreshSvg from './shared/refresh.svg'
import calenderSvg from './shared/calender.svg'

function DateRangeFormat({ occurrence, organization, showRecurring = false, compactStyle = false }) {
  const startDate = new Date(occurrence.start_date);
  const endDate = new Date(occurrence.end_date);

  const startDay = startDate.getDate();
  const startDayShort = startDate.toLocaleDateString("en-US", { weekday: "short" }); // Change locale and add weekday
  const startMonth = startDate.toLocaleDateString("en-US", { month: "short" });
  const startYear = startDate.getFullYear();
  const startTime = startDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false });

  const endDay = endDate.getDate();
  const endMonth = endDate.toLocaleDateString("en-US", { month: "short" });
  const endYear = endDate.getFullYear();
  const endTime = endDate.toLocaleTimeString("en-US", { hour: '2-digit', minute: '2-digit', hour12: false });


  const forStartDay = occurrence.formatted_start_day.split(' ')
  const startMonthV2 = forStartDay[2]
  const startDayV2 = forStartDay[1]

  if (startMonth === endMonth) {
    // Same month
    if (startDay === endDay) {
      // Same day
      return (
        compactStyle ?
          <div className="wrap-date-location">
            <span className="dateTitle">
              {/* {startDayShort} {startDay} {startMonth} {startTime} - {endTime} */}
              {occurrence.formatted_period}
            </span>
            <span className="location">{occurrence.location}</span>
            {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
          </div>
          :
          <div className="date-range-container">
            <div className="d-flex align-items-center justify-content-around">
              <div className="month-day">
                <div className="month">{startMonthV2}</div>
                <div className="day">{startDayV2}</div>
              </div>
              <div className="wrap-date-location">
                <span className="dateTitle">
                  {/* {startDayShort} {startDay} {startMonth} {startTime} - {endTime} */}
                  {occurrence.formatted_period}
                </span>
                <span className="location">{occurrence.location}</span>
                {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
              </div>
              <div>
                {!showRecurring && <img src={ArrowSvg} alt="Arrow" />}
                {showRecurring && <a href={'/' + organization.id + '/events/' + occurrence.organization_event_id} onClick={(e) => e.stopPropagation()} className="viewAll d-flex align-items-center">
                  <img src={calenderSvg} alt="" width={15} />
                  <span>View All</span>
                </a>}
              </div>
            </div>
          </div>
      );
    } else {
      // Different days
      return (
        compactStyle ?
          <div className="wrap-date-location">
            <span className="dateTitle">
              {/* {startDayShort} {startDay} {startMonth} {startTime} - {endTime} */}
              {occurrence.formatted_period}
            </span>
            <span className="location">{occurrence.location}</span>
            {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
          </div>
          :
          <div className="date-range-container">
            <div className="d-flex align-items-center justify-content-around">
              <div className="month-day">
                <div className="month">{startMonthV2}</div>
                <div className="day">{startDayV2}</div>
              </div>
              <div className="wrap-date-location">
                <span className="dateTitle">
                  {/* {startDay} {startMonth} {startTime} - {endDay} {endMonth} {endTime} */}
                  {occurrence.formatted_period}
                </span>

                <span className="location">{occurrence.location}</span>
                {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
              </div>
              <div className='viewAll'>
                {!showRecurring && <img src={ArrowSvg} alt="Arrow" />}
                {showRecurring && <a href={'/' + organization.id + '/events/' + occurrence.organization_event_id} onClick={(e) => e.stopPropagation()} className="viewAll d-flex align-items-center">
                  <img src={calenderSvg} alt="" width={15} />
                  <span>View All</span>
                </a>}
              </div>
            </div>
          </div>
      );
    }
  } else if (startYear === endYear) {
    // Different months, same year
    return (
      compactStyle ?
        <div className="wrap-date-location">
          <span className="dateTitle">
            {/* {startDayShort} {startDay} {startMonth} {startTime} - {endTime} */}
            {occurrence.formatted_period}
          </span>
          <span className="location">{occurrence.location}</span>
          {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
        </div>
        :
        <div className="date-range-container">
          <div className="d-flex align-items-center justify-content-around">
            <div className="month-day">
              <div className="month">{startMonthV2}</div>
              <div className="day">{startDayV2}</div>
            </div>
            <div className="wrap-date-location">
              <span className="dateTitle">
                {/* {startDay} {startMonth} {startTime} - {endDay} {endMonth} {endTime} */}
                {occurrence.formatted_period}
              </span>
              <span className="location">{occurrence.location}</span>
              {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
            </div>
            <div>
              {!showRecurring && <img src={ArrowSvg} alt="Arrow" />}
              {showRecurring && <a href={'/' + organization.id + '/events/' + occurrence.organization_event_id} onClick={(e) => e.stopPropagation()} className="viewAll d-flex align-items-center">
                <img src={calenderSvg} alt="" width={15} />
                <span>View All</span>
              </a>}
            </div>
          </div>
        </div>
    );
  } else {
    // Different years
    return (
      compactStyle ?
        <div className="wrap-date-location">
          <span className="dateTitle">
            {/* {startDayShort} {startDay} {startMonth} {startTime} - {endTime} */}
            {occurrence.formatted_period}
          </span>
          <span className="location">{occurrence.location}</span>
          {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
        </div>
        :
        <div className="date-range-container">
          <div className="d-flex align-items-center justify-content-around">
            <div className="month-day">
              <div className="month">{startMonthV2}</div>
              <div className="day">{startDayV2}</div>
            </div>
            <div className="wrap-date-location">
              <span className="dateTitle">
                {/* {startDay} {startMonth} {startYear} @ {startTime} - {endDay} {endMonth} {endYear} {endTime} */}
                {occurrence.formatted_period}
              </span>
              <span className="location">{occurrence.location}</span>
              {organization && <span className="orgName">{organization.display_name ?? organization.name}</span>}
            </div>
            <div>
              {!showRecurring && <img src={ArrowSvg} alt="Arrow" />}
              {showRecurring && <a href={'/' + organization.id + '/events/' + occurrence.organization_event_id} onClick={(e) => e.stopPropagation()} className="viewAll d-flex align-items-center">
                <img src={calenderSvg} alt="" width={15} />
                <span>View All</span>
              </a>}
            </div>
          </div>
        </div>
    );
  }
}

export default DateRangeFormat;
