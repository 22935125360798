import React, { useState, useEffect } from 'react';
const moment = require('moment');
require('moment-timezone');

function Countdown({ startDate, onCountdownFinished, occurrenceId, eventId, ticketId, countMessage }) {

    const [countdown, setCountdown] = useState(null);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const stockholmTime = moment().tz('Europe/Stockholm');
            const now = stockholmTime.format('YYYY-MM-DDTHH:mm:ss.SSS') + 'Z';

            const targetDate = new Date(startDate).getTime();
            const distance = targetDate - new Date(now).getTime();

            if (distance <= 0) {
                // Event has started or passed
                setCountdown(null);
                clearInterval(intervalId);
                if (onCountdownFinished) {
                    onCountdownFinished(eventId, occurrenceId, ticketId); // Trigger the callback function
                }
            } else {
                // Calculate remaining time
                const days = Math.floor(distance / (1000 * 60 * 60 * 24));
                const hours = Math.floor(
                    (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
                );
                const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
                const seconds = Math.floor((distance % (1000 * 60)) / 1000);

                // Determine the format based on the remaining time
                let countdownString = '';
                if (days > 0) {
                    countdownString = `${days} Day${days > 1 ? 's' : ''}`;
                } else if (hours > 0) {
                    countdownString = `${hours} Hour${hours > 1 ? 's' : ''}`;
                } else if (minutes > 0) {
                    countdownString = `${minutes} Minute${minutes > 1 ? 's' : ''}`;
                } else {
                    countdownString = `${seconds} Second${seconds > 1 ? 's' : ''}`;
                }

                setCountdown(countdownString);
            }
        }, 1000);

        return () => {
            clearInterval(intervalId);
        };
    }, [startDate]);

    return countdown && <div>{countMessage} {countdown}</div>;
}

export default Countdown;
