import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { UPLOAD_DOCUMENT } from './shared/Steps';

const VerifyError = ({ prevStep, nextStep, handleChange, values }) => {

  var { first_name, organization, packages, selected_package, org_id, canCompletePackageResult } = values

  const [verificationError, setVerificationError] = useState();
  const [segmentError, setSegmentError] = useState();

  const getVerificationTypeIdError = (results) => {
    for (var result of results) {
      if (!result.result && !result.requires_verification) {
        return result.verification_type_id
      }
    }
    return null
  }

  const getSegmentError = (results) => {
    for (var result of results) {
      if (!result.result && !result.requires_verification && result.message) {
          return result.message
      }
    }
    return null
  }

  useEffect(() => {

      const segmentError = getSegmentError(canCompletePackageResult)
      if (segmentError) {
        setSegmentError(segmentError)
        return
      }

      let error = getVerificationTypeIdError(canCompletePackageResult)
      if (error) {
        setVerificationError(error)
        return
      } else {
        nextStep(UPLOAD_DOCUMENT)
      }
  }, [canCompletePackageResult])

  let extraText

  if (segmentError) {
    extraText = segmentError
  } else if (verificationError === 2) {
    extraText = 'You are a member of ' + organization.display_name + ', and therefore can not purchase this package'
  } else if (verificationError === 3) {
    extraText = 'Must be at least 18 years old and a student at university level. You can not purchase this package'
  }

  const _package = packages.find(_package => _package.id === selected_package)

  
  const Home = e => {
    e.preventDefault();

    window.location.reload(false);
  }

  return (
    <>
      <Header title="Verification error" first_name={first_name} />
      <div className="container mb-5 mw-container">

        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">

              <div className="alert alert-danger text-dark mt-2">
                <p>This member can not purchase this package: "{_package.name}"</p>
                {extraText && <p>{extraText}</p>}
              </div>

              <button type="submit" onClick={Home} className="btn btn-outline-primary w-100 btn-lg btn-block mb-4 px-5">Back</button>

            </div>
          </div>
        </div>

      </div>
    </>
  )
}

export default VerifyError
