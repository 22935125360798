import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { VERIFY_FAIL } from './shared/Steps';
import ErrorMessage from './shared/ErrorMessage';

const ConfirmDuplicateMembership = ({ prevStep, nextStep, handleChange, values }) => {

  var { first_name, selected_package, packages, meta, canCompletePackageResult } = values

  const [membershipDuplicate, setMembershipDuplicate] = useState();
  const [fail, setFail] = useState();

  useEffect(() => {
    var duplicateData = getDuplicatedMemberships(canCompletePackageResult)
    if (duplicateData && duplicateData.length > 0) {
      setMembershipDuplicate(duplicateData)
    } else {
      nextStep(VERIFY_FAIL)
    }
  }, [canCompletePackageResult])

  const getDuplicatedMemberships = (results) => {
    var duplicateData = []

    if (results && results.length > 0) {
        for (var result of results) {
            if (result.member_membership_duplicate) {
              let duplicate = isDuplicateMembership(result)
              for (var duplicateResult of duplicate) {
                duplicateData.push(duplicateResult)
              }
            }
        }
    }
    
    return duplicateData
  }

  /**
   * Check if the period of date + days is between validFrom and validTo
   */
  const isBetweenPeriod = (date, days, validFrom, validTo) => {
    var endDate = new Date(date)
    endDate.setDate(endDate.getDate() + days)

    let dateFrom = Date.parse(validFrom)
    let dateTo = Date.parse(validTo)

    let dateStart = Date.parse(date)
    let dateEnd = endDate

    let startInRange = dateStart.valueOf() >= dateFrom.valueOf() && dateStart.valueOf() <= dateTo.valueOf()
    let endInRange = dateEnd.valueOf() >= dateFrom.valueOf() && dateEnd.valueOf() <= dateTo.valueOf()

    return startInRange || endInRange
  }

  const isDuplicateMembership = (result) => {

    const _package = packages.find(_package => _package.id === selected_package)

    let duplicateMemberships = []

    var duplicateSubscriptionId = result.membership_subscription_id
    let membershipSubscription = _package.membershipSubscriptions.find(membershipSubscription => membershipSubscription.id === duplicateSubscriptionId)

    if (!membershipSubscription) {
      return duplicateMemberships
    }
    
    for (var duplicate of result.duplicate) {

      let data = {
        membership_subscription_id: duplicateSubscriptionId,
        member_membership: duplicate,
        duration_days: membershipSubscription.duration_days,
        duration_type: membershipSubscription.duration_type
      }

      if (membershipSubscription.duration_type === 3) {

        // get start/end dates of selected shit
        if (meta && meta.membership_subscriptions && meta.membership_subscriptions.length > 0) {

          for (var membershipMeta of meta.membership_subscriptions) {
            if (membershipMeta.id === duplicateSubscriptionId) {
              var isDuplicate = isBetweenPeriod(membershipMeta.membership_start_date, membershipSubscription.duration_days, duplicate.valid_from, duplicate.valid_to)
              if (isDuplicate) {
                duplicateMemberships.push(data)
              }
            }
          }
        }
  
      } else {
        duplicateMemberships.push(data)
      }

    }

    return duplicateMemberships
  }

  // for continue event listener
  const Continue = e => {
    if (e) {
      e.preventDefault();
    }

    nextStep(VERIFY_FAIL)
  }

  const Previous = e => {
    e.preventDefault();

    // Reset meta on back!
    //handleChange('meta', null )
    prevStep();
  }

  if (fail) {
    return <>
            <Header title="Confirm" first_name={first_name} />
            <ErrorMessage message={fail} />
           </>
  }

  const _package = packages.find(_package => _package.id === selected_package)
  if (membershipDuplicate && membershipDuplicate.length > 0) {
    
    return <>
      <Header title="Confirm" first_name={first_name} />
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">

              <div className="alert alert-danger text-dark mt-2">
                {membershipDuplicate.map(membershipDuplicate => {
                  let membershipSubscription = _package.membershipSubscriptions.find(membershipSubscription => membershipSubscription.id === membershipDuplicate.membership_subscription_id)
                  let membership = membershipSubscription.membership
                  let validFrom = membershipDuplicate.member_membership.valid_from ? membershipDuplicate.member_membership.valid_from : membership.valid_from
                  let validTo = membershipDuplicate.member_membership.valid_to ? membershipDuplicate.member_membership.valid_to : membership.valid_to
                  return <p key={membershipDuplicate.member_membership.id}>You already have the membership <strong>{membership.name}</strong> on your profile.<br/>This membership is valid from {new Date(validFrom).toISOString().split('T')[0]} to {new Date(validTo).toISOString().split('T')[0]}. </p>
                })}
                <p>Do you still want to purchase <strong>{_package.name}</strong> ?</p>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="mw-container mx-auto mt-5 pb-5">
                    <div className="d-flex justify-content-between mt-4">
                      <button type="submit" onClick={Previous} className="btn btn-outline-primary btn-lg btn-block mb-4 px-5">Back</button>
                      <button type="submit" onClick={Continue} className="btn btn-primary btn-lg btn-block mb-4 px-5">Yes</button>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  }
 
  return (
    <>
        Loading...
    </>
  )
}

export default ConfirmDuplicateMembership
