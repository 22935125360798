import React, { useState } from "react";
import Header from './shared/Header';
import { registerMemberInterest } from '../api/apiHelper';
import { getAccessToken } from "./shared/Token";
import { useNavigate } from "react-router-dom";
import InterestCardSmall from "./InterestCardSmall";

const RegisterInterest = ({ prevStep, nextStep, handleChange, values }) => {

  const navigate = useNavigate();
  var { interest, first_name, last_name, meta, personal_identification_number, organization_details, scope } = values

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [message, setMessage] = useState(null)
  const [successMessage, setSuccessMessage] = useState(null)

  const onRegisterInterestSubmit = (e) => {
    e.preventDefault()

    if (isSubmitting) {
      return
    }

    setIsSubmitting(true)
    const accessToken = getAccessToken()
    const occurrenceId = interest?.organization_event_occurrences?.[0]?.id

    let _package
    let occurrence
    if (occurrenceId) {
        occurrence = {
            id: occurrenceId
        }
    }

    registerMemberInterest(interest?.id, _package, occurrence, meta, accessToken, (response) => {
        if (response?.result) {
            const encryptedId = response?.id
            if (encryptedId) {
              navigate(`/interest/success?id=${encryptedId}`);
            }
    
            setSuccessMessage("Thank you for your registering your interest. You may now close this page")
            setMessage(null)
            setIsSubmitting(false)
          } else {
            setIsSubmitting(false)
            setMessage(response?.message ?? "Something went wrong")
          }
    }, (error) => {
        setIsSubmitting(false)
        setMessage(error?.message ?? "Something went wrong")
    })
  }


  const renderInterestItem = (e) => {
    return <InterestCardSmall values={{ interest: interest, organization_details: organization_details }} />
  }

  const buttonContent = isSubmitting ? <div className="spinner-border" /> : "Send"

  return (
    <>
      <Header title="Confirm & pay" first_name={first_name} />
      <div className="container membership">
        <div className="mw-container mx-auto mb-2 mt-5">
          <h6>{first_name && first_name}, please complete your registration of interest.</h6>
        </div>
      </div>

      <div className="container membership">
        <div className="mw-container mx-auto">
          <h6><strong>Your Info</strong></h6>
          <div className="card">
            <div className="card-footer">
              <dl>
                {first_name && <><dt>Name:</dt><dd>{first_name}{' '}{last_name}</dd></>}
                {personal_identification_number && <><dt>Personal Number:</dt><dd>{personal_identification_number}</dd></>}
              </dl>
            </div>
          </div>
        </div>
      </div>

      {renderInterestItem()}

      <div className="container">
        <div className="row">
          <div className="col-12 mx-auto my-3">
            <div className="mw-container mx-auto">
              <div className="accordion mb-4" id="accordionPaymentOptions">
                <form id="payment-form" onSubmit={onRegisterInterestSubmit}>
                  <button type="submit" disabled={isSubmitting || successMessage} className="btn btn-primary btn-lg w-100 mt-3">{buttonContent}</button>
                </form>
                {message &&
                  <div className="alert alert-danger text-dark mt-2">{message}</div>
                }
                {successMessage && 
                  <div id="payment-message">{successMessage}</div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default RegisterInterest