import React, { useState, useEffect, useRef } from "react";
import Header from './shared/Header';
import MembershipCard from './MembershipCard';
import { createPaymentIntent, createSwishPaymentRequest, createTransaction, swishMonitor, createNetsPayment, updateNetsPayment, changeTransactionEmail } from '../api/apiHelper';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { getAccessToken } from "./shared/Token";
import Loading from "./Loading";
import EventCard from "./EventCard";
import { useFormInputValidation } from "react-form-input-validation";
import { useNavigate } from "react-router-dom";

const Payment = ({ prevStep, nextStep, handleChange, values }) => {

  const navigate = useNavigate();
  var { scope, first_name, last_name, email, meta, personal_identification_number, packages, selected_package, events, selected_ticket, organization_details, scope } = values

  // const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC);
  const [clientSecret, setClientSecret] = useState("");
  const [paymentExpireIn, setPaymentExpireIn] = useState(null);
  const [message, setMessage] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [activeTab, setActiveTab] = useState("");
  const [swishPayActive, setSwishPayActive] = useState(false);
  const [swishMessage, setSwishMessage] = useState(null)
  const [transactionActive, setTransactionActive] = useState(false)
  const [transactionMessage, setTransactionMessage] = useState(null)
  const [transactionSuccessMessage, setTransactionSuccessMessage] = useState(null)
  const [swishUUID, setSwishUUID] = useState("")
  const [showPopup, setShowPopup] = useState(false);

  const [transactionId, setTransactionId] = useState(null)
  const [receiptEmail, setReceiptEmail] = useState(email)
  const [isChangingEmail, setIsChangingEmail] = useState(false)
  const [isFullDiscount, setIsFullDiscount] = useState(false)
  const [showDiscount, setShowDiscount] = useState(false)


  const [discountCode, setDiscountCode] = useState("");
  const [discountValue, setDiscountValue] = useState(0); // To store the discount amount
  const [discountError, setDiscountError] = useState(""); // To handle any errors

  var intervalID;

  const receiptEmailDebounceTime = 500 // ms
  const receiptEmailTimeoutRef = useRef(null);

  var [fields, errors, form] = useFormInputValidation(
    {
      email: email || ""
    },
    {
      email: "required|email"
    }
  );

  /**
   * Change email, send a request to server after 500 ms if the user hasnt typed
   */
  const updateReceiptEmail = (e) => {
    const newEmail = e.target.value
    setReceiptEmail(newEmail);
    form.handleChangeEvent(e) 

    form.handleBlurEvent(e)

    if (errors.email) {
      // email not valid, return
      return
    }

    if (!transactionId) {
      return
    }

    clearTimeout(receiptEmailTimeoutRef.current);

    receiptEmailTimeoutRef.current = setTimeout(() => {

      if (errors.email) {
        return
      }

      var accessToken = getAccessToken()
      changeTransactionEmail(transactionId, newEmail, accessToken, (response) => {
        // change email ok
      }, (error) => {
        console.log("Change email error", error)
      })
    }, receiptEmailDebounceTime);

  }

  const applyDiscountCode = async (e) => {
    e.preventDefault();
    setDiscountError(null)

    var accessToken = getAccessToken()

    let _package
    let ticket
    if (scope === 'ticket') {
      ticket = {
        id: selected_ticket
      }
    } else {
      _package = {
        id: selected_package
      }
    }

    updateNetsPayment(_package, ticket, receiptEmail, meta, accessToken, discountCode, transactionId, (data) => {
      if(data.id){
        if (data.becomeFree) {
          setIsFullDiscount(true);
        } else {
          setDiscountValue(data.discountValue);
        }

        setTransactionId(null)
        setTimeout(() => {
          setTransactionId(data.id)
          setClientSecret(data.client_secret)
          setPaymentExpireIn(data.expires_in)
        }, 200);
      }else{
        setDiscountError(data.message)
      }
    }, (error) => {
      setDiscountError(error.message)
    })

  };

  useEffect(() => {
    let interval;

    if (paymentExpireIn !== null && paymentExpireIn > 0) {
      interval = setInterval(() => {
        setPaymentExpireIn((prevExpireIn) => {
          if (prevExpireIn > 0) {
            return prevExpireIn - 1;
          } else {
            return 'expired';
          }
        });
      }, 1000);
    } else if (paymentExpireIn === 0) {
      setShowPopup(true);
    }

    return () => {
      clearInterval(interval);
    };
  }, [paymentExpireIn]);

  const formatTime = (time) => {
    if (time === 'expired') {
      return time;
    }
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');
    return `${formattedMinutes}:${formattedSeconds}`;
  };


  useEffect(() => {
    if (getItemPrice() > 0) {
      var accessToken = getAccessToken()

      let _package
      let ticket
      if (scope === 'ticket') {
        ticket = {
          id: selected_ticket
        }
      } else {
        _package = {
          id: selected_package
        }
      }

      createNetsPayment(_package, ticket, receiptEmail, meta, accessToken, (data) => {
        if (data?.id) {
          setTransactionId(data.id)
          setClientSecret(data.client_secret)
          setPaymentExpireIn(data.expires_in)
        } else {
          setMessage(data?.message ?? "Something went wrong")
        }
      }, (error) => {
        setMessage(error.message)
      })
    }
  }, [paymentMethod])

  const toggleShowDiscount = e => {
    setShowDiscount(prevState => !prevState);
  }

  // for continue event listener
  const Continue = e => {
    e.preventDefault();
    nextStep();
  }

  const Previous = e => {
    e.preventDefault();
    prevStep();
  }

  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };

  const handleClick = e => {
    e.preventDefault();
    setSwishMessage(null)
    setSwishPayActive(false)

    // Set Active Tab
    var name = e.target.getAttribute("data-name") ?? e.target.id
    if (name) {
      setActiveTab(name)
    } else {
      setActiveTab(name)
    }

    // Set Active Payment Option
    // if (name === 'tab1') {
    //   setPaymentMethod('Swish')
    // } else if (name === 'tab2') {
    //   setSwishPayActive(false)
    //   setPaymentMethod('Nets')
    // }
  }

  const onFreeTransactionSubmit = (e) => {
    e.preventDefault()
    if (transactionActive) {
      return
    }

    setTransactionActive(true)
    var accessToken = getAccessToken()

    let _package
    let ticket
    if (scope === 'ticket') {
      ticket = {
        id: selected_ticket
      }
    } else {
      _package = {
        id: selected_package
      }
    }

    createTransaction(_package, ticket, receiptEmail, meta, accessToken, discountCode, transactionId, (data) => {

      if (data?.result) {
        const encryptedId = data?.id
        if (encryptedId) {
          navigate(`/success?id=${encryptedId}`);
        }

        setTransactionSuccessMessage("Thank you for your purchase. You may now close this page")
        setTransactionMessage(null)
        setTransactionActive(false)
      } else {
        setTransactionActive(false)
        setTransactionMessage(data?.message ?? "Something went wrong")
      }
      
    }, (error) => {
      setTransactionActive(false)
      setTransactionMessage(error?.message ?? "Something went wrong")
    })
  }

  const onSwishSubmit = e => {
    e.preventDefault()
    setSwishMessage(null)
    setSwishPayActive(true)

    var accessToken = getAccessToken()
    var mobileNumber = e.target.mobile.value

    let _package
    let ticket
    if (scope === 'ticket') {
      ticket = {
        id: selected_ticket
      }
    } else {
      _package = {
        id: selected_package
      }
    }

    // Create Payment Request on Submit
    createSwishPaymentRequest(_package, ticket, receiptEmail, meta, mobileNumber, accessToken, (data) => {
      if (data && data.id) {
        setSwishUUID(data.id)
        setPaymentExpireIn(data.expires_in)
      } else {
        setSwishMessage('Something went wrong!')
        setSwishPayActive(false)
      }
    }, (error) => {
      setSwishMessage(error.message)
      setSwishPayActive(false)
    })

  }

  useEffect(() => {
    if (swishUUID) {
      intervalID = setInterval(fetchSwish, 5000);
    }
  }, [swishUUID])

  const stopInterval = e => {
    clearInterval(intervalID);
  }

  const getItemPrice = () => {
    if (scope === 'ticket') {

      for (var event of events) {
        if (event.organization_event_occurrences && event.organization_event_occurrences.length > 0) {
          for (var occurrence of event.organization_event_occurrences) {
            if (occurrence.tickets && occurrence.tickets.length > 0) {
              for (var ticket of occurrence.tickets) {
                if (ticket.id === selected_ticket) {
                  return ticket.price
                }
              }
            }
          }
        }
      }

    } else if (scope === 'package') {
      for (var _package of packages) {
        if (_package.id === selected_package) {
          return _package.price
        }
      }
    } else {
      return 0
    }
  }

  const renderPurchaseItem = (e) => {
    if (scope === 'ticket') {

      for (var event of events) {
        if (event.organization_event_occurrences && event.organization_event_occurrences.length > 0) {
          for (var occurrence of event.organization_event_occurrences) {
            if (occurrence.tickets && occurrence.tickets.length > 0) {
              for (var ticket of occurrence.tickets) {
                if (ticket.id === selected_ticket) {
                  return <EventCard event={event} handleChange={handleChange} values={{ selected_ticket: selected_ticket, ticket: ticket, selected_occurrence: occurrence, meta: meta, organization_details: organization_details, isFullDiscount: isFullDiscount, discountValue: discountValue }} count={false} checkbox={false} markInactive={false} />
                }
              }
            }
          }
        }
      }

    } else {

      return packages.map(function (pack, i) {
        if (pack.id === selected_package) {
          return <MembershipCard pack={pack} key={i} values={{ selected_package: pack.id, subscription_meta: meta,  isFullDiscount: isFullDiscount, discountValue: discountValue }} organization_details={organization_details} checkbox={false} />
        }
      })

    }
  }

  const fetchSwish = e => {
    var accessToken = getAccessToken()
    swishMonitor(swishUUID, accessToken, (data) => {
      if (data) {

        // 0 init
        if (data.status === 0) {
          // init
          // do nothing
        }

        if (data.status === 2) {
          // failed
          stopInterval()
          setSwishMessage('Payment failed. Please try again later')
          setSwishPayActive(false)
          setPaymentExpireIn(null)
        }

        if (data.status === 8) {
          // Swish status CANCELLED
          stopInterval()
          setSwishMessage('Payment was cancelled')
          setSwishPayActive(false)
          setPaymentExpireIn(null)
        }

        if (data.status === 9) {
          // Swish status DECLINED
          stopInterval()
          setSwishMessage('Payment was declined')
          setSwishPayActive(false)
          setPaymentExpireIn(null)
        }

        // 3, 4 = Success, 1 = Processing
        if (data.status === 4 || data.status === 3 || data.status === 1) {
          stopInterval()
          window.location.href = '/success?payment_intent=' + data.transaction_id
        }

      } else {
        setSwishMessage('Something went wrong!')
        setSwishPayActive(false)
        setPaymentExpireIn(null)
        stopInterval()
      }
    }, (error) => {
      setSwishMessage(error.message)
      setSwishPayActive(false)
      setPaymentExpireIn(null)
      stopInterval()
    });

  }

  // const onSwishCancel = e => {
  //   e.preventDefault()
  //   setSwishPayActive(false)
  //   stopInterval()
  // }

  const isPaid = getItemPrice() > 0
  const freeTransactionButtonContent = transactionActive ? <div className="spinner-border" /> : "Complete purchase"

  return (
    <>
      <Header title="Confirm & pay" first_name={first_name} />
      <div className="container membership">
        <div className="mw-container mx-auto mb-2 mt-5">
          <h6>{first_name && first_name}, please complete your purchase.</h6>
        </div>
      </div>

      <div className="container membership">
        <div className="mw-container mx-auto">
          <h6><strong>Your Info</strong></h6>
          <div className="card">
            <div className="card-footer">
              <dl>
                {first_name && <><dt>Name:</dt><dd>{first_name}{' '}{last_name}</dd></>}
                {personal_identification_number && <><dt>Personal Number:</dt><dd>{personal_identification_number}</dd></>}
                <dt>Email:</dt>
                <dd>{receiptEmail}</dd>
                {!isChangingEmail && <p onClick={(e) => setIsChangingEmail(true)}><strong><a href="#" className="link-account d-block">Change email</a></strong></p>}

                {isChangingEmail && <div className="form-floating">
                  <input
                    onChange={updateReceiptEmail}
                    defaultValue={receiptEmail}
                    onBlur={form.handleBlurEvent}
                    className="form-control"
                    type="text" id="email" name="email" placeholder='First email' />
                  <label className="form-label" htmlFor="email">Email</label>
                  {errors.email ? (
                    <span className="text-danger small">{errors.email}</span>
                  ) : null}
                  <br /></div>}

                <small className="d-flex align-items-center">
                  <span>
                    <svg style={{ marginRight: "5px" }} xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" className="bi bi-info-circle" viewBox="0 0 16 16">
                      <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                      <path d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </span>
                  <span>
                    {scope === 'ticket' ? ' Receipt and Tickets will be sent to this email address. Your Tickets can also be found in the STUK.CO app.' : ' Receipt will be sent to this email address.'}
                  </span>
                </small>

              </dl>
            </div>
          </div>
        </div>
      </div>

      {renderPurchaseItem()}

      {(!isPaid || isFullDiscount) && <div className="container">
        <div className="row">
          <div className="col-12 mx-auto my-3">
            <div className="mw-container mx-auto">
              <div className="accordion mb-4" id="accordionPaymentOptions">
                <form id="payment-form" onSubmit={onFreeTransactionSubmit}>
                  <button type="submit" disabled={transactionActive || transactionSuccessMessage} className="btn btn-primary btn-lg w-100 mt-3">{freeTransactionButtonContent}</button>
                </form>
                {transactionMessage &&
                  <div className="alert alert-danger text-dark mt-2">{transactionMessage}</div>
                }
                {
                  transactionSuccessMessage && (<div id="payment-message">{transactionSuccessMessage}</div>)
                }
              </div>
            </div>
          </div>
        </div>
      </div>}

      {(isPaid && !isFullDiscount) && <div className="container">
        <div className="row">
          <div className="col-12 mx-auto mb-3">
            
            <div className="mw-container mx-auto">

              <div className="card-footer mb-1 cursor-pointer mt-2" onClick={()=>toggleShowDiscount()}>
                <dt>{showDiscount ? '-' : '+'} USE DISCOUNT CODE</dt>
              </div>

              <div className="accordion mb-4" id="accordionPaymentOptions">
              
              <div className={ showDiscount ? "mb-4 d-block" :  "mb-4 d-none" }>
                <form onSubmit={applyDiscountCode}>
                  <div className="input-group">
                    <input
                      type="text"
                      className="form-control"
                      value={discountCode}
                      onChange={(e) => setDiscountCode(e.target.value)}
                      placeholder=""
                      required
                    />
                    <div className="input-group-append"> 
                      <button type="submit" className="btn btn-primary none-border-left"><small className="mini-font px-4">Add</small></button>
                    </div>
                  </div>
                  {discountError && <div className="text-danger mt-2 mini-font">{discountError}</div>}                  
                </form>
              </div>

                <h6 className="mt-4"><strong>Choose payment method</strong></h6>
                {paymentExpireIn !== null && (
                  <p className="expireIn">
                    Remaining time to complete booking: {paymentExpireIn === 'expired' ? 'expired' : <span className="timer">{formatTime(paymentExpireIn)}</span>}
                  </p>
                )}

             
                
                <div className="accordion-item">
                  
                  <div id="collapseTwo" className="accordion-collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionTab2">
                    <div className="accordion-body">
                       
                      {
                        transactionId && (
                          <CheckoutForm transactionId={transactionId} />
                        )
                      }

                    </div>
                  </div>
                </div>
                

              </div>

                {
                message && (<div className="alert alert-danger text-dark mt-2">{message}</div>)
                }

            </div>
          </div>
        </div>
      </div>}

      {showPopup && (
        <div className="container overlay">
          <div className="popup">
            <h2>Time's up!</h2>
            <p>The payment time has expired.</p>
            <button type="button" className="btn btn-primary btn-lg" onClick={(e) => window.location.reload(false)}>Try again</button>
          </div>
        </div>
      )}
    </>
  )
}

export default Payment