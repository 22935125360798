const ErrorMessage = ({ message }) => {
  
    return <>
        <div className="container mb-5">
            <div className="row">
                <div className="col-12">
                    <div className="mw-container mx-auto mt-5">
                        <div className="alert alert-danger text-dark mt-2">
                            {message}
                        </div>
                    </div>
                </div>
            </div>
        </div>
          </>
  }
  
export default ErrorMessage