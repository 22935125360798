import React, { useState, useEffect } from 'react'
import Header from './shared/Header';
import { useLocation } from 'react-router-dom';
import { getMemberInterestBySecureId } from '../api/apiHelper'
import { SUCCESS } from './shared/Steps';
import checkSvg from './shared/check.svg';
import InterestCardSmall from './InterestCardSmall';

const InterestSuccess = () => {
  const [memberInterest, setMemberInterest] = useState();
  const [interest, setInterest] = useState();
  const [logOutPath, setLogOutPath] = useState();
  const [member, setMember] = useState();
  const [callDone, setCallDone] = useState(false);

  const location = useLocation()
  const params = new URLSearchParams(location.search);
  const secureMemberInterestId = params.get("id")

  useEffect(() => {
    if (secureMemberInterestId) {
        getMemberInterestBySecureId(secureMemberInterestId, (data) => {
        populateMemberInterest(data)
      }, (error) => {
        setMemberInterest(null)
        setCallDone(true)
      })
    }
  }, [])

  const populateMemberInterest = (data) => {
    if (data) {
        setLogOutPath(data?.interest?.organization_id + '/interests')
        setMemberInterest(data)
        setMember(data?.member)
        setInterest(data?.interest)
    } else {
        setMemberInterest(null)
    }
    setCallDone(true)
  }

  const renderInterest = (e) => {
    if (interest) {
        return <InterestCardSmall values={{interest: interest, organization_details: interest?.organization}} />
    } 
    return null
  }

  const hasMemberInterest = () => {
    return memberInterest && memberInterest !== undefined && Object.keys(memberInterest).length > 0
  }

  if (!callDone) {
    return <div className="container mb-5 mw-container mt-5 text-center">
        <div className="spinner-border" />
        </div>
  }

  if (memberInterest?.error) {
    return <>
            <Header title="Not Found!" />
            <div className="container mb-5 mw-container mt-5 text-center">
                <h4>Not found!</h4>
            </div>
        </>
  }

  if (!hasMemberInterest()) {
    return <>
            <Header title="Not Found!" />
            <div className="container mb-5 mw-container mt-5 text-center">
                <h4>Something went wrong. Please try again later.</h4>
            </div>
          </>
  }

  return (
        <>
          <Header title="Thank you!" first_name={member?.first_name} step={SUCCESS} orgId={logOutPath} />
          <div className="container mb-3 mw-container mt-5 text-center">
            <>
                <img src={checkSvg} width={60} alt="check" title="check" className='checkImg' />
                <h4><strong>Thank you!</strong></h4>
            </>
            <p><b>Your registration of interest has been received.</b></p>
          </div>
          <div className="container membership">
            <div className="card mw-container mx-auto mt-4">
              <div className="card-footer">
                <div className="row">
                  <div className="col-6">
                    <dl>
                      <dt>Name:</dt>
                      <dd>{member?.first_name}{' '}{member?.last_name}</dd>
                    </dl>
                  </div>
                  <div className="col-6">
                    <dl>
                      <dt>Personal Number:</dt>
                      <dd>{member?.personal_identification_number}</dd>
                    </dl>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {renderInterest()}
        </>
  )
}

export default InterestSuccess
