import React from 'react'
import Header from './shared/Header';
import { useState, useEffect } from 'react';
import { INTEREST_FORM_PAGE, REGISTER_INTEREST_CONFIRM } from './shared/Steps';
import { getInterestWithForm } from '../api/apiHelper';
import { getAccessToken } from './shared/Token';

const VerifyMemberInterest = ({ prevStep, nextStep, handleChange, values }) => {

  var { first_name, interest, verifyInterestResult } = values

  const [verifyInterestError, setVerifyInterestError] = useState();

  useEffect(() => {

    if (!verifyInterestResult || !verifyInterestResult.result) {
        setVerifyInterestError(verifyInterestResult?.message)
    } else if (verifyInterestResult.result) {

      const accessToken = getAccessToken()
      getInterestWithForm(interest?.id, accessToken, (interestForms) => {
          if (interestForms && interestForms.length > 0) {
            handleChange('forms', interestForms)
            nextStep(INTEREST_FORM_PAGE)
          } else {
            nextStep(REGISTER_INTEREST_CONFIRM)
          }
        }, (error) => {
            setVerifyInterestError('Something went wrong. Please try again later')
        })
    } else {
        setVerifyInterestError('Something went wrong. Please try again later')
    }

  }, [verifyInterestResult])

  if (verifyInterestError) {

    return <>
      <Header title="Confirm" first_name={first_name} />
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">

              <div className="alert alert-danger text-dark mt-2">
                <p>{verifyInterestError}</p>
              </div>

            </div>
          </div>
        </div>
      </div>
    </>
  }
 
  return (
    <>
      <Header title="Confirm" first_name={first_name} />
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5" style={{textAlign: 'center'}}>
              Loading...
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default VerifyMemberInterest
