import React from 'react'
import Header from './shared/Header';
import { login, getMember, createPendingMember, canCompletePackage, verifyTicket, getInterestWithForm, verifyInterest } from '../api/apiHelper'
import { useState, useEffect } from 'react';
import Personnummer from 'personnummer';
import { getAccessToken, saveToken, removeTokens } from './shared/Token';
import { useFormInputValidation } from "react-form-input-validation";
import { CONFIRM_DUPLICATE_MEMBERSHIP, FORM_PAGE, INTEREST_FORM_PAGE, PAYMENT, REGISTER_INTEREST_CONFIRM, VERIFY, VERIFY_INTEREST } from './shared/Steps';

const UserDetails = ({ prevStep, nextStep, handleChange, values }) => {

  var { scope, interest, events, selected_occurrence, password, repeat_password, first_name, last_name, personal_identification_number, email, mobile_number, street_address, co_address, zip_code, city, selected_package, selected_ticket, org_id, organization_details, organization, packages, meta } = values

  const [loginPin, setLoginPin] = useState();
  const [loginPassword, setLoginPassword] = useState();
  const [fail, setFail] = useState();
  const [member, setMember] = useState();
  const [pinActive, setPinActive] = useState(true);
  const [showPersonalInfo, setShowPersonalInfo] = useState(false);
  const [statePassword, setStatePassword] = useState("");
  const [requestError, setRequestError] = useState("");


  var [fields, errors, form] = useFormInputValidation(
    {
      email: email || "",
      first_name: first_name || "",
      personal_identification_number: personal_identification_number || "",
      last_name: last_name || "",
      password: password || "",
      repeat_password: repeat_password || "",
      mobile_number: mobile_number || "",
      street_address: street_address || "",
      zip_code: zip_code || "",
      city: city || "",
      co_address: co_address || ""
    },
    {
      email: "required|email",
      first_name: "required",
      last_name: "required",
      password: "password_validation",
      repeat_password: "repeat_password_validation",
      personal_identification_number: "pin_validation",
      mobile_number: "required|numeric|digits_between:10,12",
      street_address: "required",
      zip_code: "required|numeric|digits:5",
      city: "required",
      co_address: "string"
    }
  );

  form.register("password_validation", function (
    value, requirement, attribute
  ) {
    return value.length >= 6
  }, "Must be at least 6 characters.");

  form.register("repeat_password_validation", function (
    value, requirement, attribute
  ) {
    return value == statePassword
  }, "Passwords are not matcing");

  useEffect(() => {
    form.registerAsync("pin_validation", function (
      pin,
      attribute,
      req,
      passes
    ) {
      if (validatePIN(pin)) {
        passes(true);
      } else {
        passes(false, "Incorrect Personal Number.");
      }
    });

  }, [form]);

  useEffect(() => {
    var access_token = getAccessToken()

    if (access_token !== undefined) {
      getMember(access_token, (member_data) => {
        if (member_data && member_data.id) {

          // TODO DEV-2355 line below is not working..
          //setMember(member_data, Continue());
          setMember(member_data)
          setTimeout(() => {
            Continue()
          }, 500);

        }
      }, (error) => {
        setMember(null)
        removeTokens()
      })
    }
  }, [])

  useEffect(() => {
    if (member) {
      handleChange('first_name', member.first_name)
      handleChange('last_name', member.last_name)
      handleChange('personal_identification_number', member.personal_identification_number)
      handleChange('email', member.email)
      handleChange('password', member.password)
      handleChange('repeast_password', member.repeat_password)
      handleChange('street_address', member.street_address)
      handleChange('co_address', member.co_address)
      handleChange('zip_code', member.zip_code)
      handleChange('city', member.city)
    }
  }, [member, handleChange])

  // for continue event listener
  const Continue = e => {

    if (e) {
      e.preventDefault();
    }

    if (scope === 'ticket') {

      var accessToken = getAccessToken()

      let ticket = {
        id: selected_ticket
      }
      verifyTicket(ticket, meta, accessToken, (result) => {
        handleChange('verifyTicketResult', result)
        nextStep(VERIFY)
      }, (error) => {
        setFail('Something went wrong. Please try again later')
      })

    } else if (scope === 'interest') {
        const accessToken = getAccessToken()
        verifyInterest(interest?.id, accessToken, (result) => {
            handleChange('verifyInterestResult', result)
            nextStep(VERIFY_INTEREST)
        }, (error) => {
          setFail('Something went wrong. Please try again later')
        })
    } else {
      var accessToken = getAccessToken()
      canCompletePackage(selected_package, accessToken, (results) => {
        handleChange('canCompletePackageResult', results)
        nextStep(CONFIRM_DUPLICATE_MEMBERSHIP)
      }, (err) => {
        setFail('Something went wrong. Please try again later')
      })
    }

  }

  const Previous = e => {
    e.preventDefault();

    // Reset meta on back!
    handleChange('meta', null)
    if (scope === 'ticket') {
      nextStep(12);
    } else {
      prevStep();
    }
  }

  const CreatePendingMember = (event) => {
    //e.preventDefault();
    createPendingMember(first_name, last_name, personal_identification_number, email, mobile_number, password, repeat_password, (token) => {
      saveToken(token)
      Continue(event)
    }, (error) => {
      var message = error?.message
      if (error?.code == 'DUPLICATE_ENTITY') {
        message = 'Member already exists. Please Sign in above.'
      }

      setRequestError(message)
    })
  }

  const onSubmit = async (event) => {
    event.preventDefault()
    const isValid = await form.validate(event);
    if (isValid) {
      CreatePendingMember(event)
    }
  };

  const onLoginChange = input => e => {
    input(e.target.value)
  }

  const Login = e => {
    e.preventDefault();
    var self = this

    login(loginPin, loginPassword, (data) => {

      if (data && data.status !== 'success') {
        setFail('Incorrect email address or password!')
      }
      if (data.access_token) {
        saveToken(data)
        setFail('')

        getMember(data.access_token, (member_data) => {

          // set member and continue!
          // TODO DEV-2355 this is not working
          //setMember(member_data, Continue());

          setMember(member_data)
          setTimeout(() => {
            Continue()
          }, 500);

        }, (error) => {
          console.log('error getting member!', error)
        })
      }
    }, (error) => {
      console.log('error!', error)
    })

  }

  // eslint-disable-next-line no-extend-native
  String.prototype.replaceAt = function (index, replacement) {
    return this.substring(0, index) + replacement + this.substring(index + replacement.length);
  }

  const validatePIN = (pin) => {
    // Check if first char from last 4 digits has string like T,P,U,X etc, Change it to 1 before validation.
    if (pin && pin.length > 4) {
      if (isNaN(pin[pin.length - 4])) {
        pin = pin.replaceAt(pin.length - 4, "1");
      }
    }

    return Personnummer.valid(pin)
  }

  const onClickNoPin = (e) => {
    e.preventDefault();
    setPinActive(!pinActive)

    let pin = document.getElementById('personal_number')
    if (pin.disabled) {
      pin.disabled = false;
      pin.focus()
    } else {
      pin.value = "";
      pin.disabled = true;
    }

    if (pin.disabled) {
      let input = document.getElementById('email')
      input.focus()
    }
  }

  const handleBlur = e => {

    if (validatePIN(loginPin) && loginPin !== '') {
      e.target.classList.add("is-valid");
      e.target.classList.remove("is-invalid");
    } else if (!validatePIN(loginPin) && loginPin !== '') {
      e.target.classList.add("is-invalid");
      e.target.classList.remove("is-valid");
    } else {
      e.target.classList.remove("is-valid");
      e.target.classList.remove("is-invalid");
    }
  };

  return (

    // TODO DEV-2355 log in with email/password?
    <>
      <Header title="Login / Sign Up" first_name={first_name} />
      <div className="container mb-5">

        <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5">
              {!member &&
                <form className="mt-5" onSubmit={Login}>
                  <p><strong>Sign In</strong></p>
                  <label htmlFor="pin" className="form-label">Personal Number or Email Address</label>
                  <div className="form mb-3">
                    <input autoComplete="off" type="text"
                      className="form-control"
                      onBlur={handleBlur}
                      id="pin"
                      onChange={onLoginChange(setLoginPin)} />
                  </div>
                  <div className="form mb-3">
                    <label htmlFor="password" className="form-label">Password</label>
                    <input type="password" className="form-control" id="password" onChange={onLoginChange(setLoginPassword)} />
                  </div>
                  <button type="submit" className="btn btn-primary btn-lg w-100" disabled={!loginPin || !loginPassword} >Sign In</button>
                  <a className="link-account text-center d-block my-3" href="https://www.studentkortet.se/logga-in" target="_blank" rel="noreferrer" >Forgot Password?</a>

                  {fail && <div className="alert alert-danger text-dark mt-2">
                    {fail}
                  </div>}
                </form>

              }
            </div>
          </div>
        </div>

        {!member &&
          <>
            <div className="row">
              <div className="col-12">
                <div className="mw-container mx-auto mt-5 pb-5">

                  <hr className="hr-text" />

                  <div className="text-center d-block my-2">New to STUK.CO?</div>

                  <a className="link-account text-center d-block my-3" onClick={() => setShowPersonalInfo(!showPersonalInfo)} href="#">Create Account</a>


                  {showPersonalInfo && (
                    <form
                      autoComplete="off"
                      id="myform"
                      onSubmit={onSubmit}
                    >
                      <div className="row mt-4 mb-3">
                        <div className="my-2">
                          <strong>Create a STUK.CO Account</strong>
                        </div>

                        <div className="col">
                          <div className="form">
                            <label className="form-label" htmlFor="first_name">First Name</label>
                            <input
                              onChange={(e) => { handleChange('first_name', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={first_name}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="text" id="first_name" name="first_name" />
                            {errors.first_name ? (
                              <span className="text-danger small">{errors.first_name}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col">
                          <div className="form">
                            <label className="form-label" htmlFor="last_name">Last Name</label>
                            <input
                              onChange={(e) => { handleChange('last_name', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={last_name}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="text" id="last_name" name="last_name" />
                            {errors.last_name ? (
                              <span className="text-danger small">{errors.last_name}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">

                          <div className="form mb-3">
                            <label htmlFor="personal_number" className="form-label">Personal Number</label>
                            <input
                              onChange={(e) => { handleChange('personal_identification_number', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={personal_identification_number}
                              onBlur={form.handleBlurEvent}
                              className="form-control w-100"
                              data-attribute-name="Personal Number"
                              data-async
                              type="text" id="personal_number" name="personal_identification_number" />

                            {errors.personal_identification_number && pinActive ? (
                              <span className="text-danger small">{errors.personal_identification_number}</span>
                            ) : null}

                            <a className="link-account d-block my-1" onClick={(e) => { onClickNoPin(e) }} href="#">
                              I {pinActive && "don't"} have a personal identification number
                            </a>


                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form mb-3">
                            <label className="form-label" htmlFor="email">Email Address</label>
                            <input
                              onChange={(e) => { handleChange('email', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={email}
                              onBlur={form.handleBlurEvent}
                              type="email" id="email" name="email"
                              className="form-control"
                            />

                            {errors.email ? (
                              <span className="text-danger small">{errors.email}</span>
                            ) : null}
                          </div>
                          <div className="form mb-3">
                            <label className="form-label" htmlFor="mobile_number">Mobile Number</label>
                            <input
                              onChange={(e) => { handleChange('mobile_number', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={mobile_number}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="text" id="mobile_number" name="mobile_number" />
                            {errors.mobile_number ? (
                              <span className="text-danger small">{errors.mobile_number}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col">
                          <div className="form mb-3">
                            <label className="form-label" htmlFor="password">Password</label>
                            <input
                              onChange={(e) => { handleChange('password', e.target.value); setStatePassword(e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={password}
                              onBlur={form.handleBlurEvent}
                              type="password" id="password" name="password"
                              className="form-control"
                            />

                            {errors.password ? (
                              <span className="text-danger small">{errors.password}</span>
                            ) : null}
                          </div>
                          <div className="form mb-3">
                            <label className="form-label" htmlFor="repeat_password">Repeat password</label>
                            <input
                              onChange={(e) => { handleChange('repeat_password', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={repeat_password}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="password" id="repeat_password" name="repeat_password" />
                            {errors.repeat_password ? (
                              <span className="text-danger small">{errors.repeat_password}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>

                      {/* <div className="row">
                        <div className="col">
                          <div className="form-floating mb-3">
                            <input
                              onChange={(e) => { handleChange('street_address', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={street_address}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="text" id="street_address" name="street_address" placeholder="Street Address" />
                            <label className="form-label" htmlFor="street_address">Street Address</label>
                            {errors.street_address ? (
                              <span className="text-danger small">{errors.street_address}</span>
                            ) : null}
                          </div>
                          <div className="form-floating mb-3">
                            <input
                              onChange={(e) => { handleChange('co_address', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={co_address}
                              onBlur={form.handleBlurEvent}
                              type="text" id="co_address" name="co_address" className="form-control" placeholder="CO Address" />
                            <label className="form-label" htmlFor="co_address">C/O Address</label>
                          </div>
                        </div>
                      </div> */}

                      {/* <div className="row">
                        <div className="col-4">
                          <div className="form-floating mb-3">
                            <input
                              onChange={(e) => { handleChange('zip_code', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={zip_code}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              data-attribute-name="Post Code"
                              type="text" id="zip_code" name="zip_code" placeholder='Post Code' />
                            <label className="form-label" htmlFor="zip_code">Post Code</label>
                            {errors.zip_code ? (
                              <span className="text-danger small">{errors.zip_code}</span>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-8">
                          <div className="form-floating mb-3">
                            <input
                              onChange={(e) => { handleChange('city', e.target.value); form.handleChangeEvent(e) }}
                              defaultValue={city}
                              onBlur={form.handleBlurEvent}
                              className="form-control"
                              type="text" id="city" name="city" placeholder='City' />
                            <label className="form-label" htmlFor="city">City</label>
                            {errors.city ? (
                              <span className="text-danger small">{errors.city}</span>
                            ) : null}
                          </div>
                        </div>
                      </div> */}


                      <button type="submit" id="submit-form" className={"btn btn-primary btn-lg btn-block mb-4 px-5 w-100"}>Next</button>

                    </form>
                  )}

                  {requestError ? (
                    <span className="text-danger small">{requestError}</span>
                  ) : null}

                </div>
              </div>
            </div>
          </>}

        {/* <div className="row">
          <div className="col-12">
            <div className="mw-container mx-auto mt-5 pb-5">
              <div className="d-flex justify-content-between mt-4">
                <button type="submit" onClick={Previous} className="btn btn-outline-primary btn-lg btn-block mb-4 px-5">Back</button>
                {member ?
                  <button type="submit" onClick={Continue} className="btn btn-primary btn-lg btn-block mb-4 px-5">Next</button>
                  :
                  <label htmlFor="submit-form" type="submit" className={!showPersonalInfo ? "btn btn-primary btn-lg btn-block mb-4 px-5 disabled" : "btn btn-primary btn-lg btn-block mb-4 px-5"}>Next</label>
                }
              </div>
            </div>
          </div>
        </div> */}

      </div>
    </>
  )
}

export default UserDetails
