import React, { useState } from 'react';
import { getInterestDescription, getInterestName } from './shared/Util';

const InterestCard = ({ interest, organization_details, language }) => {

  const [activeTab, setActiveTab] = useState('About');

  const Tab = ({ activeTab, label, onClick }) => (
    <li className="nav-item" role="presentation">
        <button className={activeTab === label ? 'active nav-link' : 'nav-link'} onClick={() => onClick(label)}
        data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-selected={activeTab === label ? "true" : "false"}>{label}</button>
    </li>
  );

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };
  
  // if interest is connected to event - show event image etc, same as event page?
  return (
    <>
      {interest &&
        <div className="container membership packages">
          <div className="row overflow-hidden">
            <div className="col-12 mx-auto" id={'interest-' + interest.id}>

                <div className="row overflow-hidden"><div className="col-12 mx-auto">
                    <div className="card mw-container">
                      {organization_details && organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
                      <div className="card-body bg-white d-flex justify-content-between align-items-center ">
                        <div>
                          <h6 className="card-title">{getInterestName(interest, language)}</h6>
                          {organization_details && <span className="orgName">{organization_details.display_name ?? organization_details.name}</span>}
                        </div>
                        {organization_details && organization_details.logo_image_url && <img src={organization_details.logo_image_url} height="65" alt="logo" className="unionLogo" />}
                      </div>
                    </div>
                  </div>
                </div>
               

              <div className="card mw-container packages-item">

                <ul className="nav nav-pills" id="myTab" role="tablist">
                  {interest?.description && <Tab activeTab={activeTab} label="About" onClick={handleTabClick} />}
                </ul>

                {activeTab === 'About' && (
                  <div className="card-footer">
                    <div className="card-text">
                      <div dangerouslySetInnerHTML={{ __html: getInterestDescription(interest, language) }} />
                    </div>
                  </div>)
                }

              </div>
            </div>
          </div>
        </div >
      }
    </>
  )

}

export default InterestCard