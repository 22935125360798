import { SUCCESS } from './Steps';
import { removeTokens, saveToken, getAccessToken, getRefreshToken } from './Token';
import logoutSvg from './logout.svg';


const logout = (step, orgId) => {
  removeTokens()
  if (step && orgId && step === SUCCESS) {
    window.location.href = '/' + orgId
  } else {
    window.location.href = window.location.href.split("?")[0];
  }
}

const Header = ({ organization_details, first_name, step, orgId }) => {
  var accessToken = getAccessToken()

  return (
    <header>
      <div className="d-flex justify-content-center align-items-center">
        <div className="center-logo">
          {/* {organization_details ?
            <a href={'/' + organization_details.organization_id + '/events'}>
              <img src={"https://www.studentkortet.se/wp-content/themes/studentkortet/images/logos/stuk-black.svg"} height={15} alt="Stuk" />
            </a>
          : */}
          <img src={"https://www.studentkortet.se/wp-content/themes/studentkortet/images/logos/stuk-black.svg"} height={15} alt="Stuk" />
          {/* } */}
        </div>

        {/* {first_name && accessToken && */}

        {accessToken &&
          <div className="logout">
            {/* {organization_details &&
            <img src={organization_details.logo_image_url} height={65} alt="logo" className="unionLogo" />
          } */}
            <span onClick={() => logout(step, orgId)} className="logout" >
              <img src={logoutSvg} width={16} alt="logout" title="logout" />
            </span>
          </div>
        }

      </div>
    </header>
  )
}

export default Header