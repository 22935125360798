import React, { useEffect } from 'react'
import { useState } from "react"
import { REGISTER_INTEREST_CONFIRM } from './shared/Steps';
import Header from './shared/Header';
import { getInterestName } from './shared/Util';
import { getLanguage } from './shared/Token';

const InterestForm = ({ prevStep, nextStep, handleFormChange, values }) => {

    var { formResult, interest, organization_details } = values
    const language = getLanguage()

    const [form, setForm] = useState(null)

    const formInterestIndex = 0

    useEffect(() => {
        if (values.forms && values.forms.length > 0) {
            let form = values.forms[0]
            form.form.form_form_items.sort((a, b) => a.order - b.order);
            setForm(form.form)
        }   
    }, [values.forms])

    // should we render event image if available?
    const renderFormHeader = (form, interest) => {        
        return <div className="row">
                <div className="col-12 mx-auto">
                    <div className="card mw-container">
                    {organization_details && organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
                        <div className="card-body bg-white d-flex justify-content-between align-items-center border-b">
                            <div>
                                <h5 className="card-title ">{form.name}</h5>
                                <div className="wrap-ticket-name">
                                    <span className="ticketName d-block">{getInterestName(interest, language)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
    }

    const renderForm = (form) => {
        return <> 
            {renderFormHeader(form, interest)}
            {renderFormItems(form.form_form_items)}
        </>
    }

    const Continue  = e => {
        if (e) {
          e.preventDefault();
        }

        nextStep(REGISTER_INTEREST_CONFIRM)
    }

    const getFormOptionValue = (formId, formItemId, formOptionId) => {
        var result = formResult?.filter((result) => result.index === formInterestIndex)?.[0]?.result
        
        let formItem = result?.form_items?.find((formItem) => formItem.id == formItemId)
        let formOption = formItem?.form_options?.find((formOption) => formOption.id == formOptionId)
       
        if (!formOption) {
            return false
        }
        return formOption.value
    }

    const getTextFormOptionValue = (formId, formItemId, formOptionId) => {
        let value = getFormOptionValue(formId, formItemId, formOptionId)
        if (!value) {
          return ''
        }
        return value
    }

    // Check if user has answered the *Required* formItems
    const canUserProceed = () => {
        if (!form || !formResult) {
            return false;
        }
    
        const formFormItems = form.form_form_items || [];
        const requiredFormItemIds = formFormItems
            .filter(item => item?.form_item?.required)
            .map(item => item.form_item.id);
    
        const result = formResult
            .filter(result => result.index === formInterestIndex)
            .flatMap(result => result?.result?.form_items || [])
            .map(formItem => formItem.id);
    
        const allRequiredItemsAnswered = requiredFormItemIds.every(itemId => result.includes(itemId));
        return allRequiredItemsAnswered 
    }
    
    const handleFormAnswers = (formId, formItemId, formOptionId, formOptionValue, formOption) => {
        let result = formResult?.filter((result) => result.index === formInterestIndex)?.[0]?.result;
        const interestFormsId = values.forms?.[0]?.id;
    
        if (!result) {
            result = {};
        }
    
        result.form_id = formId;
        result.interest_form_id = interestFormsId;
    
        if (!result.form_items) {
            result.form_items = [];
        }
    
        let formItem = result.form_items.find((formItem) => formItem?.id == formItemId);
        let formItemWithType = form.form_form_items.find((formItem) => formItem?.form_item_id === formItemId)
       
        if (!formItem) {
            const newFormItem = { // Create new object
                id: formItemId,
                form_options: [
                    {
                        id: formOptionId,
                        value: formOptionValue
                    }
                ]
            };
            result.form_items.push(newFormItem);
            handleFormChange(interestFormsId, formInterestIndex, result, interest?.id, 'interests', 'interest_form');
            return;
        }
    
        const existingFormOption = formItem.form_options.find(option => option.id === formOptionId);
    
        if (existingFormOption) { // Update existing option
            existingFormOption.value = formOptionValue;
            if (existingFormOption.value === false || existingFormOption.value === '') { // Remove from object if value is false (uncheck) or text is empty string 
                formItem.form_options = formItem.form_options.filter((formOption) => formOption.id !== formOptionId);
                if (!formItem.form_options.length) {
                    result.form_items = result.form_items.filter((formItem) => formItem.id !== formItemId);
                    if (!result.form_items.length) {
                        result = null;
                    }
                }
            }
        } else {
            if (formOption.type === 'multiple_choice') { // Replace option if multiple_choice (can only be one answer for multiple_choice)
                const newFormOption = {
                    id: formOptionId,
                    value: formOptionValue
                };
                formItem.form_options = [newFormOption];
            } else {
                if(formItemWithType.form_item.type !== 'multiple_choice') {
                    const newData = {
                        id: formOptionId,
                        value: formOptionValue
                    };
                    formItem.form_options.push(newData);
                } else { // If text option answer in multiple_choice formItem, replace option (can only be one answer for multiple_choice)
                    const newFormOption = {
                        id: formOptionId,
                        value: formOptionValue
                    };
                    formItem.form_options = [newFormOption];
                }
            }
        }
    
        handleFormChange(interestFormsId, formInterestIndex, result, interest?.id, 'interests', 'interest_form');
    };

    const renderFormItems = (formItems) => {
        return formItems.map((item, index) => (
            item && item.form_item && item.form_item.title && (
                <div key={item.id} className='form-item border-b' style={{background: 'white', padding: '15px', marginTop: '20px'}}>
                    <>
                        <p className='form-item-title'><b>{item.form_item.required ? ( <> {item.form_item.title} <span style={{color: 'red'}}>*</span> </>): item.form_item.title}</b></p>
                        {renderFormItemOptions(item.form_item, item.form_item.form_options)}
                    </>
                    </div>
            )
        ));
    }

    const renderFormItemOptions = (formItem, formOptions) => {
        if(!formOptions) {
            return
        }
        
        return formOptions.map((option, index) => {
            switch(option.type) {
                case 'multiple_choice':
                    return renderMultipleChoiceFormOption(option, formItem)
                case 'checkbox':
                    return renderCheckboxFormOption(option, formItem);
                case 'text':
                    return renderTextFormOption(option, formItem)
                default:
                    break;
            }
        })
    }

  const renderMultipleChoiceFormOption = (formOption, formItem) => {
    return (
        <div className="form-check" style={{ marginTop: '15px' }} key={formOption.id}>
            <input
                className="form-check-input"
                type="radio"
                name={`multiple_choice_${formItem.id}`}
                id={formOption.id}
                value={formOption.id}
                onChange={(e) => handleFormAnswers(form.id, formItem.id, formOption.id, e.target.checked, formOption)}
                checked={getFormOptionValue(form.id, formItem.id, formOption.id)}
            />
            <label htmlFor={formOption.id} style={{ marginLeft: '.5em' }}>{formOption.value}</label>
        </div>
    );
  }

  const renderCheckboxFormOption = (formOption, formItem) => {
    return (
    <div className="form-check" style={{marginTop: '15px'}} key={formOption.id}>
    <input className="form-check-input" type="checkbox" name="checkbox" id={formOption.id}
        value = {formOption.id}
        onChange={(e) => handleFormAnswers(form.id, formItem.id, formOption.id, e.target.checked, formOption)}
        checked={getFormOptionValue(form.id, formItem.id, formOption.id)}
        />
        <label htmlFor={formOption.id} style={{marginLeft: '.5em'}}>{formOption.value}</label>
    </div>
    )
  }

  const renderTextFormOption = (formOption, formItem) => {
        return (
            <div className="form mb-3" style={{marginTop: '15px'}} key={formOption.id}>
            {formOption && formItem.type !== 'text' && <label htmlFor="text">{formOption.value || formItem.title}</label>} 
            <input autoComplete="off" type="text" name="text"
                placeholder={formOption.placeholder !== 'Write placeholder text here...' ? formOption.placeholder : ""} 
                className="form-control"
                value={getTextFormOptionValue(form.id, formItem.id, formOption.id)}
                onChange={(e) => handleFormAnswers(form.id, formItem.id, formOption.id, e.target.value, formOption)}
                style={{marginTop: '5px'}}
                />
            </div>
    )
  }
    
    
    return <>
    <Header></Header>
         <div className="container mb-5">
            <div className="row">
            <div className="col-12">
                <div className="mw-container mx-auto ticketForm">
                {form && renderForm(form)}
                </div>
            </div>
            </div>
            <div className="row">
            <div className="col-12">
                <div className="mw-container mx-auto mt-4 pb-5">
                 <div className="d-flex justify-content-between ">
                <button type="submit" onClick={Continue} disabled={!canUserProceed()} className="btn btn-primary btn-lg btn-block w-100 mb-4 px-5">{"Next"}</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </>
}

export default InterestForm;