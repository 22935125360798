import React from 'react'
import Header from './shared/Header';

const NotFound = () => {
  return (
    <>
      <Header title="Not Found" />
      <div className="container mb-5 mw-container mt-5 text-center">
          <h2>404</h2>
          <p>The page you are looking for doesn't exist or an other error occurred.</p>
      </div>
    </>
  )
}

export default NotFound
