import React from "react";
import Header from './shared/Header'
import { USER_DETAILS } from './shared/Steps';
import { getLanguage } from "./shared/Token";
import InterestListItem from "./InterestListItem";

const AllInterests = ({ nextStep, prevStep, handleChange, values }) => {

  var { first_name, interests, organization_details } = values
  const language = getLanguage()

  return (
    <>
      <Header title="Select Interest" first_name={first_name} />

      {organization_details &&
        <div className="container membership">
          <div className="row overflow-hidden"><div className="col-12 mx-auto">
            <div className="card mw-container">
              {organization_details.image_url && <img src={organization_details.image_url} className="w-100" alt="" />}
              <div className="card-body bg-white d-flex justify-content-between align-items-center topPackages">
                <div>
                  {organization_details.name && <h5 className="card-title">{organization_details.name}</h5>}
                </div>
                {organization_details.logo_image_url && <img src={organization_details.logo_image_url} height="65" alt="logo" className="unionLogo" />}
              </div>
            </div>
          </div>
          </div>
        </div>
      }

      {!interests || interests.length < 1 ?
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto my-4 apply-btn mw-container">
              <h6>We're sorry, but at this time there are options available. Please check back later as new options may become available.</h6>
            </div>
          </div>
        </div>
        : interests.map(function (interest, i) {
            return <InterestListItem key={i} interest={interest} organization_details={organization_details} language={language} />
        })
      }
    </>
  )

}

export default AllInterests